body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif !important;
}

/* Table Design Mixin Start */
@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    padding: 0;
    list-style: none;

    > * {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -($item-size / 2);
        width: $item-size;
        height: $item-size;

        $angle: (360 / $item-count);
        $rot: 0;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg) translate($circle-size / 1.4) rotate(90deg);
                &::before {
                    counter-increment: number-counter;
                    content: " " counter(number-counter) " ";
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 18px;
                    position: absolute;
                    top: -8px;
                    left: 50%;
                    -ms-transform: translate(-50%, 0);
                    -webkit-transform: translate(-50%, 0);
                    -moz-transform: translate(-50%, 0);
                    -o-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                }
            }

            $rot: $rot + $angle;
        }
    }
}
/* Table Design Mixin Ends */
* {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
button span {
    font-family: "Montserrat", sans-serif !important;
}

a {
    cursor: pointer;
}

/* Modal Design Css Start */
body {
    .MuiAutocomplete-popper {
        z-index: 99999;
    }
    .cus-modal {
        z-index: 99999 !important;
        &.modal-expenditure-main {
            z-index: 1300 !important;
        }
        .modal-title {
            padding: 20px 20px 0 0;
            button {
                &.close {
                    float: right;
                    padding: 5px;
                }
            }
        }
        .modal-form {
            width: 100%;
            padding: 0 40px 40px 40px;
            display: inline-block;
            .db-sub-heading {
                padding: 0 0 10px 0;
            }
            .add-fileds-modal {
                padding: 0 10%;
                button {
                    &.cus-btn {
                        margin-top: 8px;
                    }
                }
            }
        }
        .MuiPaper-root {
            @include box-shadow;
            border-radius: 20px;
        }
        &.modal-lg {
            .MuiPaper-root {
                width: 1280px;
            }
        }
        &.modal-md {
            .MuiPaper-root {
                width: 1024px;
            }
        }
        &.modal-sm {
            .MuiPaper-root {
                width: 750px;
            }
        }
        &.modal-xs {
            .MuiPaper-root {
                width: 600px;
            }
        }
    }
    .user-dropdown {
        .MuiMenu-paper {
            top: 75px !important;
            width: 180px;
            margin-left: -15px;
            @include box-shadow;
            ul {
                padding: 0;
                li {
                    padding: 10px 20px;
                    color: $dark-grey-color;
                    font-weight: normal;
                    font-size: 16px;
                    border-bottom: 1px solid $light-white-color;
                    background-color: transparent;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.selected-item {
                        color: $blue-color;
                        background-color: transparent;
                        &:hover,
                        &:focus {
                            background-color: transparent;
                            color: $blue-color;
                        }
                    }
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: $blue-color;
                    }
                }
            }
        }
    }
    .MuiTooltip-popper {
        .MuiTooltip-tooltip {
            color: $black-color;
            background-color: $white-color;
            @include box-shadow;
            &.MuiTooltip-tooltipPlacementBottom {
                margin-top: 30px;
            }
            .tooltip-inner {
                p {
                    color: $dark-grey-color;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0;
                    margin: 12px;
                }
            }
        }
    }
}
/* Modal Design Css Ends */

/* Header Section Css Start */
.cus-header {
    width: 100%;
    display: inline-block;
    padding: 15px 0;
    header {
        .header-toolbar {
            .cus-logo {
                a {
                    img {
                        height: 80px;
                    }
                }
            }
            .cus-menus {
                li {
                    padding: 8px 35px;
                    a {
                        white-space: nowrap;
                        color: $dark-grey-color;
                        font-size: 14px;
                        font-weight: 500;
                        @include font-montserrat;
                        &:hover,
                        &:focus {
                            color: $blue-color;
                        }
                    }
                }
            }
            .cus-sign-search {
                .sign-in-up-sec {
                    button {
                        &.cus-btn {
                            min-width: 110px;
                            font-size: 14px;
                            height: 42px;
                            &.transparent-btn {
                                margin-right: 10px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                .header-search {
                    background-color: $light-grey-color;
                    width: 44px;
                    height: 44px;
                    margin-left: 20px;
                    border-radius: 8px;
                }
                .user-icon {
                    img {
                        background: $blue-color;
                        border-radius: 50%;
                        padding: 6px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
/* Header Section Css Ends */

/* Main Slider Section Css Start */
.cus-main-slider {
    .slider-left-sec {
        width: 100%;
        .slick-list {
            .slick-track {
                &:focus {
                    outline: none;
                }
            }
        }
        .slider-list {
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            &:focus {
                outline: none;
            }
            .slider-contents {
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                padding: 50px;
                width: 100%;
                max-width: 550px;
                h5 {
                    color: $white-color;
                    font-weight: 600;
                    font-size: 20px;
                }
                h2 {
                    font-weight: bold;
                    color: $dark-grey-color;
                    font-size: 45px;
                    margin: 10px 0px;
                }
                p {
                    font-weight: normal;
                    color: $dark-grey-color;
                    font-size: 16px;
                }
                .slider-btns {
                    width: 100%;
                    display: inline-block;
                    margin-top: 25px;
                    button {
                        margin-right: 10px;
                        min-width: 180px;
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        .slick-dots {
            bottom: 25px;
            li {
                button {
                    &::before {
                        font-size: 16px;
                        opacity: 0.45;
                        color: $white-color;
                    }
                }
                &.slick-active {
                    button {
                        &::before {
                            opacity: 1;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .slider-right-sec {
        display: flex;
        .cat-grids-sec {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    width: 50%;
                    float: left;
                    margin-bottom: 32px;
                    padding-right: 16px;
                    padding-left: 16px;
                    @include cus-transition;
                    &:hover {
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                img {
                                    @include cus-hover-transition;
                                }
                            }
                        }
                    }
                    &.cat-03,
                    &.cat-04 {
                        margin-bottom: 0px;
                    }
                    .overlap-cards-con {
                        position: relative;
                        width: 100%;
                        display: inline-block;
                        .overlap-cards-top-img {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            z-index: 2;
                            width: calc(100% - 50px);
                            -ms-transform: translate(-50%, 0);
                            -webkit-transform: translate(-50%, 0);
                            -moz-transform: translate(-50%, 0);
                            -o-transform: translate(-50%, 0);
                            transform: translate(-50%, 0);
                            display: flex;
                            border-radius: 15px;
                            overflow: hidden;
                            height: 170px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                @include cus-transition;
                            }
                        }
                        .overlap-cards-bottom-img {
                            padding: 0;
                            width: 230px;
                            position: relative;
                            height: 235px;
                            margin-top: 0;
                            background-size: contain;
                            background-position: center bottom;
                            .overlap-cards-content {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                left: 0;
                                padding: 15px 25px 10px 25px;
                                display: flex;
                                justify-content: space-between;
                                right: 0;
                                align-items: center;
                                .cat-tittle-show {
                                    width: calc(100% - 34px);
                                    display: inline-block;
                                    padding-right: 15px;
                                    h4 {
                                        color: $white-color;
                                        font-size: 18px;
                                        font-weight: 600;
                                    }
                                    p {
                                        color: $white-color;
                                        font-size: 15px;
                                        font-weight: 500;
                                    }
                                }
                                .cat-arrow {
                                    width: 34px;
                                    height: 34px;
                                    background-color: #f6805b;
                                    border-radius: 50%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    button {
                                        min-width: auto;
                                        padding: 0;
                                        min-height: auto;
                                        svg {
                                            color: $white-color;
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.cat-02 {
                        .overlap-cards-con {
                            .overlap-cards-bottom-img {
                                background-size: contain;
                                background-position: center bottom;
                                .overlap-cards-content {
                                    .cat-arrow {
                                        background-color: #4d87f9;
                                    }
                                }
                            }
                        }
                    }
                    &.cat-03 {
                        .overlap-cards-con {
                            .overlap-cards-bottom-img {
                                background-size: contain;
                                background-position: center bottom;
                                .overlap-cards-content {
                                    .cat-arrow {
                                        background-color: #3fc9a6;
                                    }
                                }
                            }
                        }
                    }
                    &.cat-04 {
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                display: none;
                            }
                            .overlap-cards-bottom-img {
                                background-size: contain;
                                background-position: center bottom;
                                .overlap-cards-content {
                                    .cat-arrow {
                                        background-color: #b67bff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Main Slider Section Css Ends */

/* Upcoming Shows Section Css Start */
.cus-upcoming-shows {
    padding: 40px 0;
    .upcoming-shows-inner {
        .upcoming-shows-listing {
            @include cus-transition;
            &:hover {
                .cus-card-inner {
                    .card-img-sec {
                        img {
                            @include cus-hover-transition;
                        }
                    }
                }
            }
            .cus-card-inner {
                border-radius: 20px;
                width: 100%;
                display: inline-block;
                overflow: hidden;
                @include box-shadow;
                .view-counts {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    z-index: 7;
                    display: flex;
                    background-color: $white-color;
                    border-radius: 7px;
                    padding: 6px 8px;
                    align-items: center;
                    justify-content: center;
                    .eye-icon {
                        width: auto;
                        display: flex;
                        margin-right: 7px;
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                    h5 {
                        font-size: 12px;
                        font-weight: 600;
                        color: $black-color;
                        span {
                            font-weight: normal;
                        }
                    }
                }
                .card-img-sec {
                    width: 100%;
                    display: inline-block;
                    height: 200px;
                    border-radius: 20px 20px 10px 10px;
                    overflow: hidden;
                    img {
                        height: 100%;
                        object-fit: cover;
                        @include cus-transition;
                    }
                }
                button {
                    @include cus-transition;
                    &:hover,
                    &:focus {
                        .MuiCardActionArea-focusHighlight {
                            opacity: 0;
                        }
                    }
                }
                .card-date {
                    width: 100%;
                    display: inline-block;
                    h5 {
                        color: $blue-color;
                        font-size: 16px;
                        padding: 0;
                        margin: 0;
                        font-weight: 500;
                    }
                    h6 {
                        color: $dark-grey-color;
                        font-size: 16px;
                        padding: 0;
                        margin: 0;
                        font-weight: 600;
                    }
                }
                .card-contents {
                    width: 100%;
                    display: inline-block;
                    h2 {
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 20px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-word;
                    }
                    p {
                        color: $dark-grey-color;
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-height: 40px;
                        word-break: break-word;
                    }
                }
                .card-bottom-con {
                    width: 100%;
                    display: inline-block;
                    .time-card {
                        width: 100%;
                        display: flex;
                        margin-bottom: 8px;
                        h5 {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            color: $dark-grey-color;
                            font-weight: 600;
                            font-size: 14px;
                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 10px;
                            }
                        }
                    }
                    .promocode-card {
                        width: 100%;
                        display: inline-block;
                        h5 {
                            color: $dark-grey-color;
                            font-weight: 600;
                            font-size: 14px;
                            span {
                                color: $blue-color;
                                margin-left: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-view-all-show {
        margin: 30px 0 20px 0;
        button {
            &.cus-btn {
                min-width: 220px;
            }
        }
    }
}
/* Upcoming Shows Section Css Ends */

/* Sell Tickets Section Css Start */
.banner-ad-sec {
    background-color: $yellow-color;
    border-radius: 20px;
    margin: 10px 0 70px;
    .ads-left-con {
        width: 100%;
        display: inline-block;
        padding: 70px;
        h5 {
            color: $dark-grey-color;
            font-weight: 600;
            font-size: 18px;
        }
        .cus-sec-heading {
            padding: 0;
            h2 {
                margin: 6px 0px;
            }
        }
        p {
            color: $dark-grey-color;
            font-size: 16px;
            margin-bottom: 20px;
        }
        button {
            min-width: 180px;
        }
    }
    .ads-img-here {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        height: 100%;
        align-items: flex-end;
        position: relative;
        background-position: center;
        background-size: 100% 320px;
        img {
            max-height: 320px;
        }
    }
}
/* Sell Tickets Section Css Ends */

/* Social Media Icons Seciton Css Start */
.fixed-social-media {
    position: fixed;
    right: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 8888;
    width: 50px;
    text-align: center;
    ul {
        width: 100%;
        display: inline-block;
        padding: 0;
        li {
            padding: 8px 0;
            justify-content: center;
            a {
                padding: 4px;
            }
            img {
                height: 20px;
                width: 20px;
            }
        }
    }
}
/* Social Media Icons Seciton Css Ends */

/* About Us Section Css Start */
.home-about-sec {
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
    .about-sec-left {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        border-radius: 20px;
        @include cus-transition;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include cus-transition;
        }
        &:hover {
            img {
                @include cus-hover-transition;
            }
        }
    }
    .about-sec-right {
        width: 100%;
        display: inline-block;
        p {
            color: $dark-grey-color;
            font-size: 16px;
            margin-bottom: 15px;
            line-height: 26px;
        }
        button {
            &.cus-btn {
                min-width: 180px;
                margin-top: 20px;
            }
        }
    }
}
/* About Us Section Css Ends */

/* Contact Us Page Css Start */
.cus-contact-page {
    width: 100%;
    display: inline-block;
    margin-bottom: 40px;
    .cus-contact-us {
        .cus-sec-heading {
            padding: 0 0 10px;
        }
        .contact-us-left-sec {
            .contact-us-left-inner {
                padding: 50px;
                background: $white-color;
                border-radius: 14px;
                p {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 16px;
                }
                .contact-form {
                    width: 100%;
                    display: inline-block;
                    margin-top: 20px;
                    .MuiFormControl-marginNormal {
                        margin-top: 8px;
                        margin-bottom: 6px;
                    }
                    .MuiOutlinedInput-root {
                        border-radius: 6px;
                    }
                    .cus-recaptcha {
                        width: 100%;
                        display: inline-block;
                        margin: 10px 0px 16px 0px;
                    }
                    button {
                        &.cus-btn {
                            height: 56px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .contact-us-right-sec {
            .contact-us-right-inner {
                padding: 50px 50px 0 50px;
                p {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 16px;
                }
                ul {
                    padding: 0;
                    margin: 15px 0 0 0;
                    list-style: none;
                    li {
                        padding: 15px 0;
                        align-items: flex-start;
                        svg {
                            color: $blue-color;
                            margin-right: 20px;
                            width: 30px;
                            height: 30px;
                        }
                        p {
                            font-weight: normal;
                        }
                        a {
                            color: $dark-grey-color;
                            text-decoration: none;
                        }
                    }
                }
                .contact-us-social-icons {
                    width: 100%;
                    display: inline-block;
                    ul {
                        padding: 0;
                        margin: 20px 0 0 0;
                        list-style: none;
                        width: 100%;
                        li {
                            display: inline-block;
                            width: auto;
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0px;
                            }
                            a {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid $black-color;
                                border-radius: 50px;
                                height: 40px;
                                width: 40px;
                                img {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Contact Us Page Css Ends */

/* FAQ Page Css Start */
.cus-faq-page {
    width: 100%;
    display: inline-block;
    margin-bottom: 40px;
    .cus-faq-con {
        .cus-sec-heading {
            padding: 0 0 20px;
        }
        .faq-options {
            ul {
                width: 100%;
                display: inline-block;
                padding: 0;
                margin: 0;
                list-style: none;
                max-width: 200px;
                li {
                    padding: 0px;
                    width: 100%;
                    display: inline-block;
                    margin: 6px 0px;
                    h3 {
                        padding: 12px 20px;
                        color: $grey-color;
                        opacity: 0.73;
                        font-size: 18px;
                        cursor: pointer;
                        font-weight: 500;
                        border-radius: 6px;
                        &.selected {
                            background-color: $blue-color;
                            color: $white-color;
                            font-weight: 600;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .cus-have-question {
        width: 100%;
        display: inline-block;
        margin: 50px 0 0 0;
        .cus-sec-heading {
            padding: 20px 0;
        }
        .cus-sec-sub-heading {
            p {
                font-size: 18px;
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}
.cus-accordion {
    width: 100%;
    display: inline-block;
    .accordion-listing {
        background-color: transparent;
        border-bottom: 1px solid $light-white-color;
        border-radius: 0 !important;
        margin: 0 !important;
        &:last-child {
            border-bottom: 0;
        }
        &:before {
            content: none;
        }
        .accordion-summary {
            padding: 20px 0;
            position: relative;
            background-color: transparent;
            z-index: 55;
            h3 {
                color: $dark-grey-color;
                font-size: 18px;
                font-weight: 600;
            }
            > div {
                margin: 0 !important;
            }
            .MuiAccordionSummary-expandIcon {
                color: $black-color;
                &.Mui-expanded {
                    -ms-transform: rotate(130deg);
                    -webkit-transform: rotate(130deg);
                    -moz-transform: rotate(130deg);
                    -o-transform: rotate(130deg);
                    transform: rotate(130deg);
                    color: $blue-color;
                }
            }
        }
        .accordion-details {
            padding: 0 0 40px 0px;
            margin: 0;
            p {
                color: $dark-grey-color;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
/* FAQ Page Css Ends */

/* Search Section Css Start */
.cus-page-search {
    width: 100%;
    display: inline-block;
    margin: 15px 0 45px;
    max-width: 40%;
    position: relative;
    .MuiFormControl-root {
        width: 100%;
    }
    input {
        background-color: $white-color;
        padding: 18.5px 60px 18.5px 14px;
    }
    .search-icon {
        position: absolute;
        right: 2px;
        top: 50%;
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        transform: translate(0, -50%);
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 4px);
        background-color: $white-color;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        img {
            width: 22px;
            height: 22px;
        }
    }
}
/* Search Section Css Ends */

/* Shows Page Css Start */
.show-detail-page {
    width: 100%;
    display: inline-block;
    .cus-show-detail-slider {
        .slick-list {
            .slick-slide {
                &:focus {
                    outline: none;
                }
            }
            div {
                &:focus {
                    outline: none;
                }
            }
        }
        .show-detail-slider {
            width: 100%;
            display: inline-block;
            padding: 0 15px;
            position: relative;
            &:focus {
                outline: none;
            }
            .detail-slider-img {
                width: 100%;
                display: inline-block;
                border-radius: 20px;
                overflow: hidden;
                img {
                    width: 100%;
                    display: inline-block;
                }
            }
            .detail-slider-con {
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                padding: 50px 80px;
                width: 100%;
                max-width: 50%;
                h5 {
                    color: $white-color;
                    font-weight: 600;
                    font-size: 20px;
                }
                h2 {
                    font-weight: bold;
                    color: $dark-grey-color;
                    font-size: 45px;
                    margin: 10px 0px;
                }
                p {
                    font-weight: normal;
                    color: $dark-grey-color;
                    font-size: 16px;
                }
                .detail-slider-btns {
                    width: 100%;
                    display: inline-block;
                    margin-top: 25px;
                    button {
                        min-width: 180px;
                    }
                }
            }
        }
        .slick-dots {
            bottom: 25px;
            li {
                button {
                    &::before {
                        font-size: 16px;
                        opacity: 0.45;
                        color: $white-color;
                    }
                }
                &.slick-active {
                    button {
                        &::before {
                            opacity: 1;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}
/* Shows Page Css Ends */

/* Filter Sidebar Css Start */
.cus-show-filters {
    max-width: 400px;
    .shows-filters-container {
        padding: 24px 18px;
        background-color: $white-color;
        border-radius: 20px;
        opacity: 1;
        max-height: calc(100vh - 200px);
        overflow: auto;
        @include box-shadow;
        .accordion-listing {
            border-bottom: 1px solid $light-white-color;
            &:before {
                content: none;
            }
            .accordion-summary {
                padding: 0;
                min-height: auto;
                .MuiAccordionSummary-expandIcon {
                    display: none;
                }
                .MuiAccordionSummary-content {
                    margin: 0;
                }
                h3 {
                    width: 100%;
                }
                .filter-heading {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 0px;
                    .filter-head-icon {
                        width: calc(100% - 35px);
                        display: flex;
                        align-items: center;
                        padding-right: 15px;
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 16px;
                        svg {
                            margin-right: 6px;
                        }
                    }
                    .clear-filter {
                        color: $grey-color;
                        font-size: 14px;
                        width: 35px;
                        text-align: right;
                    }
                }
            }
            &.Mui-expanded {
                margin: 0;
                min-height: auto;
                .Mui-expanded {
                    margin: 0;
                }
                .accordion-summary {
                    .filter-heading {
                        .filter-head-icon {
                            color: $blue-color;
                            svg {
                                color: $dark-grey-color;
                            }
                        }
                    }
                }
            }
        }
        .accordion-details {
            padding: 0;
            > p {
                width: 100%;
            }
            .MuiSlider-thumb {
                margin-left: 0;
            }
            .MuiFormControl-root {
                margin-left: 0;
                margin-right: 0;
            }
            .shows-option-list {
                width: 100%;
                display: inline-block;
                .show-option {
                    width: auto;
                    display: inline-block;
                    .cus-chip {
                        background-color: $light-blue-color;
                        border-radius: 7px;
                        color: $dark-grey-color;
                        height: auto;
                        padding: 8px 5px;
                        font-size: 13px;
                    }
                }
            }
            .date-range-sec {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 10px 4px 4px 4px;
                .date-range-inner {
                    width: auto;
                    display: inline-block;
                    img {
                        width: 14px;
                        height: 14px;
                        float: left;
                        margin-right: 7px;
                        margin-top: 5px;
                    }
                    .date-range-label {
                        color: $dark-grey-color;
                        height: auto;
                        font-size: 13px;
                    }
                }
            }
            .filter-date-picker {
                margin-top: 10px;
                .DateRangePicker {
                    .DateInput_input {
                        font-size: 14px;
                    }
                    .DateInput {
                        width: 110px;
                    }
                }
            }
        }
        .shows-filters-button {
            margin-top: 30px;
            button {
                &.cus-btn {
                    height: 42px;
                    font-size: 14px;
                }
            }
        }
    }
}
/* Filter Sidebar Css Ends */

/* Show Detail Page Css Start */
.single-show-page {
    width: 100%;
    display: inline-block;
    .single-show-inner {
        padding-bottom: 40px;
        .cus-sec-heading {
            padding: 0;
        }
        .show-detail-img {
            width: 100%;
            display: inline-block;
            position: relative;
            .view-counts {
                position: absolute;
                top: 12px;
                left: 12px;
                z-index: 7;
                display: flex;
                background-color: $white-color;
                border-radius: 7px;
                padding: 6px 8px;
                align-items: center;
                justify-content: center;
                .eye-icon {
                    width: auto;
                    display: flex;
                    margin-right: 7px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
                h5 {
                    font-size: 12px;
                    font-weight: 600;
                    color: $black-color;
                    span {
                        font-weight: normal;
                    }
                }
            }
        }
        .show-full-img {
            width: 100%;
            display: inline-block;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            max-height: 500px;
            @include cus-transition;
            .page-tag {
                position: absolute;
                border-radius: 7px;
                background-color: $white-color;
                left: 10px;
                top: 10px;
                width: auto;
                display: inline-block;
                font-size: 14px;
                font-weight: 600;
                color: $blue-color;
                padding: 7px 20px;
            }
            img {
                width: 100%;
                display: inline-block;
                object-fit: cover;
                height: 100%;
                @include cus-transition;
            }
            &:hover {
                img {
                    @include cus-hover-transition;
                }
            }
        }
        .detail-active {
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            h4 {
                width: auto;
                display: inline-block;
                background: $blue-color;
                color: $white-color;
                border-radius: 4px;
                padding: 7px 20px;
                font-size: 14px;
                font-weight: 600;
            }
        }
        .date-location {
            width: 100%;
            display: inline-block;
            margin: 20px 0 10px 0;
            ul {
                padding: 0;
                margin: 0 0 10px 0;
                list-style: none;
                li {
                    width: auto;
                    display: inline-block;
                    padding: 0;
                    margin-right: 60px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .date-loc-inner {
                        display: flex;
                        align-items: center;
                        svg {
                            color: $blue-color;
                            margin-right: 6px;
                            width: 22px;
                            height: 22px;
                        }
                        h5 {
                            font-weight: 600;
                            color: $dark-grey-color;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .show-description {
            width: 100%;
            display: inline-block;
            p {
                font-size: 16px;
                color: $dark-grey-color;
                margin-bottom: 20px;
                b {
                    font-weight: 600;
                }
                a {
                    text-decoration: underline;
                }
            }
            .show-but-ticket {
                display: inline-block;
                margin: 20px 0 0 0;
                button {
                    &.cus-btn {
                        min-width: 220px;
                    }
                }
            }
        }
    }
    .artist-cards-inner {
        padding-bottom: 40px;
        .cus-artist-cards {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    width: 180px;
                    display: inline-block;
                    padding: 0;
                    margin-right: 40px;
                    .artist-card {
                        width: 100%;
                        display: inline-block;
                        text-align: center;
                        .artist-card-img {
                            width: 180px;
                            display: inline-block;
                            height: 180px;
                            border-radius: 50%;
                            overflow: hidden;
                            @include cus-transition;
                            img {
                                width: 100%;
                                display: inline-block;
                                object-fit: cover;
                                @include cus-transition;
                            }
                        }
                        .artist-card-con {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                            h3 {
                                color: $dark-grey-color;
                                font-weight: 600;
                                font-size: 18px;
                                margin-top: 12px;
                            }
                        }
                    }
                    &:hover {
                        .artist-card {
                            .artist-card-img {
                                img {
                                    @include cus-hover-transition;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Show Detail Page Css Ends */

/* Show Detail Page Css Start */
.sell-ticket-page {
    width: 100%;
    display: inline-block;
    padding-bottom: 40px;
    .banner-ad-sec {
        background-color: $blue-normal-color;
        margin: 0px 0 60px;
    }
    .sell-tickets-listing {
        margin-bottom: 70px;
        .tickets-img {
            width: 450px;
            height: 450px;
            display: block;
            margin: 0 auto;
            text-align: center;
            border-radius: 50%;
            overflow: hidden;
            background-color: $grey-normal-color;
            @include cus-transition;
            &.easy-to-afford-sec {
                padding: 50px;
                img {
                    object-fit: contain;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include cus-transition;
            }
            &:hover {
                img {
                    @include cus-hover-transition;
                }
            }
        }
        .tickets-con {
            .cus-sec-heading {
                padding: 0 0 20px 0;
            }
            p {
                font-size: 16px;
                font-weight: normal;
                color: $dark-grey-color;
                margin-bottom: 25px;
            }
        }
    }
}
/* Show Detail Page Css Ends */

/* Banner Section Css Start */
.cus-banner {
    width: 100%;
    display: inline-block;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    .banner-contents {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 80px;
        width: 100%;
        max-width: 50%;
        h5 {
            color: $white-color;
            font-weight: 600;
            font-size: 20px;
        }
        h2 {
            font-weight: bold;
            color: $dark-grey-color;
            font-size: 45px;
            margin: 10px 0px;
        }
        p {
            font-weight: normal;
            color: $dark-grey-color;
            font-size: 16px;
            line-height: 28px;
            a {
                color: $dark-grey-color;
                font-weight: 600;
                text-decoration: underline;
            }
        }
        .banner-btns {
            width: 100%;
            display: inline-block;
            margin-top: 25px;
            button {
                margin-right: 10px;
                min-width: 180px;
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
/* Banner Section Css Ends */

/* Take Away Section Css Start */
.cus-take-away {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 60px 0 0 0;
    .take-away-img {
        width: 100%;
        display: inline-block;
        img {
            width: 140px;
            height: 140px;
        }
    }
    .cus-sec-heading {
        padding: 20px 0;
    }
    p {
        font-size: 16px;
        color: $dark-grey-color;
        a {
            color: $blue-color;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}
/* Take Away Section Css Ends */

/* Pick Your Venue Page Css Start */
.pick-your-venue-page {
    width: 100%;
    display: inline-block;
    .cus-sec-heading {
        padding: 50px 0 40px 0;
    }
    .cus-page-search {
        margin: 0;
        max-width: 300px;
        float: right;
    }
    .filter-by-category {
        width: 300px;
        display: inline-block;
        .MuiFormControl-root {
            width: 100%;
            label {
                font-weight: 600;
            }
        }
    }
    .pick-your-venue-sec {
        .cus-venue-list-sec {
            margin: 50px 0px;
            .venue-listing {
                width: 100%;
                display: inline-block;
                overflow: hidden;
                border-radius: 20px;
                padding: 25px;
                cursor: pointer;
                @include box-shadow;
                background-color: $white-color;
                &:hover {
                    .venue-img {
                        img {
                            @include cus-hover-transition;
                        }
                    }
                }
                .venue-img {
                    width: 100%;
                    display: inline-block;
                    @include cus-transition;
                    max-height: 210px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        display: inline-block;
                        @include cus-transition;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .venue-con {
                    width: 100%;
                    display: inline-block;
                    h4 {
                        font-size: 18px;
                        color: $dark-grey-color;
                        font-weight: 600;
                        margin-bottom: 12px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                    p {
                        font-size: 16px;
                        color: $dark-grey-color;
                        display: flex;
                        svg {
                            margin-right: 8px;
                            margin-top: 0;
                            color: $blue-color;
                        }
                        span {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            min-height: 48px;
                            word-break: break-word;
                        }
                    }
                }
            }
            .cus-no-venues {
                p {
                    text-align: center;
                    padding: 40px 0;
                }
            }
        }
    }
    &.db-venue-page {
        .db-heading {
            padding: 0 0 40px 0;
            button {
                &.cus-btn {
                    margin-right: 15px;
                }
            }
            .cus-page-search {
                .MuiFormControl-root {
                    margin: 0;
                }
                input {
                    padding: 10.5px 60px 10.5px 14px;
                }
                .search-icon {
                    width: 40px;
                    height: calc(100% - 16px);
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .pick-your-venue-sec {
            .cus-venue-list-sec {
                margin: 0;
            }
        }
    }
}
/* Pick Your Venue Page Css Ends */

/* Terms Conditions Page Css Start */
.cus-static-page {
    width: 100%;
    display: inline-block;
    padding-bottom: 40px;
    .cus-static-inner {
        width: 100%;
        display: inline-block;
        .cus-sec-heading {
            padding: 0 0 20px 0;
        }
    }
}
/* Terms Conditions Page Css Ends */

/* List Design Css Start */
.cus-listing-design {
    width: 100%;
    display: inline-block;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            width: 100%;
            display: inline-block;
            position: relative;
            padding: 12px 6px 12px 45px;
            &:after {
                content: "";
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                left: 2px;
                top: 14px;
                background-size: contain;
            }
            h3 {
                color: $dark-grey-color;
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 600;
            }
            p {
                color: $dark-grey-color;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
    &.cus-numbering-listing {
        ul {
            counter-reset: my-awesome-counter;
            li {
                counter-increment: my-awesome-counter;
                padding: 12px 6px 12px 30px;
                &:after {
                    content: counter(my-awesome-counter) ". ";
                    background-image: none;
                    color: $dark-grey-color;
                    font-size: 20px;
                    margin-bottom: 10px;
                    font-weight: 600;
                    top: 9px;
                    left: 0;
                }
            }
        }
    }
}
/* List Design Css Ends */

/* Auth Pages Css Start */
.cus-auth-page {
    width: 100%;
    display: inline-block;
    min-height: 100vh;
    .auth-logo {
        width: 100%;
        display: inline-block;
        margin: 40px 0px;
        min-height: 85px;
        img {
            max-width: 200px;
        }
    }
    .cus-auth-inner {
        width: 100%;
        display: inline-block;
        position: relative;
        min-height: calc(100vh - 165px);
        padding-top: 40px;
        &:after {
            content: "";
            position: absolute;
            left: 20px;
            bottom: 0;
            width: 550px;
            height: 550px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
        &:before {
            content: "";
            position: absolute;
            right: 20px;
            bottom: 0;
            width: 550px;
            height: 550px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
        .cus-auth-con {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            padding: 0 20px 10px 20px;
            .cus-sec-heading {
                padding: 20px 0;
            }
            .cus-sec-sub-heading {
                padding-bottom: 15px;
            }
            .forgot-password-sec {
                a {
                    float: right;
                    margin: 7px 0 0;
                    font-size: 16px;
                    color: $blue-color;
                    text-decoration: none;
                }
            }
            .auth-btns {
                button {
                    &.cus-btn {
                        height: 56px;
                        font-size: 16px;
                        margin-top: 15px;
                    }
                }
            }
            .auth-static-txt {
                p {
                    margin: 30px 0px;
                    color: $light-grey-normal-color;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .resend-otp-sec {
                a {
                    color: $blue-color;
                    font-size: 16px;
                    margin: 10px 0 15px 0;
                    display: inline-block;
                    text-decoration: none;
                }
            }
            .agree-con-sec {
                p {
                    color: $light-grey-normal-color;
                    margin: 8px 0 0 0;
                    font-size: 14px;
                    svg {
                        margin-left: -10px;
                        width: 18px;
                        height: 18px;
                    }
                    a {
                        color: $blue-color;
                    }
                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }
            .cus-account-txt {
                p {
                    color: $dark-grey-color;
                    margin: 10px 0 5px 0;
                    font-size: 16px;
                    a {
                        color: $blue-color;
                    }
                }
            }
            .auth-social-media {
                padding-bottom: 15px;
                ul {
                    width: 100%;
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    li {
                        padding: 0;
                        margin: 0 12px;
                        width: auto;
                        display: inline-block;
                        img {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }
            }
            .otp-fields-sec {
                > div {
                    padding: 0;
                    margin: 10px -15px;
                    width: calc(100% + 30px);
                    > div {
                        padding: 0;
                        margin: 15px;
                        input {
                            background: $light-blue-color;
                            border-radius: 4px;
                            color: $dark-grey-color;
                            font-size: 16px;
                            padding: 15px 14px;
                            width: 100% !important;
                            border: none;
                            &:focus {
                                border: none;
                                outline: none;
                            }
                        }
                    }
                }
                ul {
                    padding: 0;
                    margin: 10px -15px;
                    display: flex;
                    width: calc(100% + 30px);
                    li {
                        padding: 0;
                        margin: 15px;
                        input {
                            background: $light-blue-color;
                            border-radius: 4px;
                            text-align: center;
                            color: $dark-grey-color;
                            font-size: 16px;
                            padding: 15px 14px;
                        }
                        fieldset {
                            border-color: transparent;
                        }
                    }
                }
            }
            .MuiFormControl-marginNormal {
                margin-top: 16px;
                margin-bottom: 2px;
            }
        }
    }
}
.confirm-password-form {
    width: 100%;
    display: inline-block;
    padding: 10px 15% 60px 15%;
    .changes-pass-icon {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-bottom: 5px;
        img {
            width: 100px;
            height: 100px;
        }
    }
    .cus-sec-heading {
        padding: 0 0 30px 0;
    }
    button {
        &.cus-btn {
            margin-top: 15px;
        }
    }
}
/* Auth Pages Css Ends */

/* Tabs Design Css Start */
.cus-tabs {
    width: 100%;
    display: inline-block;
    padding: 20px 40px 30px;
    .MuiTabs-indicator {
        display: none;
    }
    header {
        background-color: transparent;
        box-shadow: none;
        button {
            background-color: $tabs-light-blue;
            opacity: 1;
            color: $dark-grey-color;
            font-weight: 600;
            flex: 1;
            text-transform: capitalize;
            font-size: 16px;
            &:first-child {
                border-radius: 30px 0 0 30px;
            }
            &:last-child {
                border-radius: 0 30px 30px 0;
            }
            &.Mui-selected {
                background-color: $blue-color;
                color: $white-color;
            }
        }
    }
}
/* Tabs Design Css Ends */

/* Tabs List Design Css Start */
.cus-tab-list {
    width: 100%;
    display: inline-block;
    header {
        background-color: transparent;
        box-shadow: none;
        .MuiTabs-root {
            min-height: 30px;
        }
        button {
            min-width: auto;
            margin-right: 60px;
            font-weight: 600;
            text-transform: capitalize;
            font-size: 16px;
            color: $grey-color;
            padding: 0;
            line-height: normal;
            height: 30px;
            min-height: 30px;
            &.Mui-selected {
                color: $blue-color;
            }
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .tab-contents {
        width: 100%;
        display: inline-block;
        padding: 25px 0;
        .tab-inner-head {
            h4 {
                font-weight: bold;
                font-size: 20px;
                color: $dark-grey-color;
                margin: 10px 0px;
            }
            button {
                &.cus-btn {
                    background-color: $white-color;
                    color: $blue-color;
                    border: 2px solid $blue-color;
                    border-radius: 4px;
                    min-width: 100px;
                    height: 44px;
                    text-transform: capitalize;
                    font-size: 16px;
                    padding: 6px 10px;
                    img {
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .cus-profile-fileds {
            ul {
                padding: 0 0 30px 0;
                li {
                    padding: 18px 0;
                    border-bottom: 1px solid $light-white-color;
                    .left-label {
                        width: 250px;
                        p {
                            color: $black-color;
                            font-size: 16px;
                        }
                    }
                    .right-field {
                        width: calc(100% - 250px);
                        p {
                            color: $black-color;
                            font-weight: 600;
                            font-size: 16px;
                            word-break: break-all;
                        }
                        a {
                            &.change-pass-txt {
                                float: right;
                                font-weight: 600;
                            }
                        }
                        .MuiFormControl-root {
                            margin: 0;
                        }
                        .MuiSelect-root {
                            &.MuiSelect-select {
                                padding: 0;
                            }
                        }
                        .datepicker-date {
                            .filed-date {
                                .MuiInputBase-root {
                                    min-width: 223px;
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                        fieldset {
                            border: none;
                        }
                        input {
                            padding: 0;
                            :placeholder {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .registered-gst {
                svg {
                    color: $blue-color;
                    margin-right: 6px;
                    width: 21px;
                    height: 21px;
                }
                p {
                    color: $dark-grey-color;
                    font-size: 14px;
                }
            }
        }
        .wallet-list {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                margin: 0;
                li {
                    padding: 0;
                    margin: 0 10px 10px 0;
                    width: calc(330px - 10px);
                    float: left;
                    .wallet-list-inner {
                        background-color: $light-blue-color;
                        border-radius: 13px;
                        width: 100%;
                        display: inline-block;
                        padding: 25px;
                        .wallet-head {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            img {
                                width: 30px;
                                height: 30px;
                                margin-right: 15px;
                            }
                            p {
                                font-weight: bold;
                                color: $black-color;
                                font-size: 20px;
                            }
                        }
                        .available-bal {
                            width: 100%;
                            display: flex;
                            margin: 22px 0;
                            align-items: center;
                            justify-content: space-between;
                            p {
                                color: $black-color;
                                font-size: 16px;
                                &.bal-here {
                                    font-weight: 600;
                                    font-size: 28px;
                                    color: $blue-color;
                                }
                            }
                        }
                        .add-money-btn {
                            width: 100%;
                            display: inline-block;
                            button {
                                &.cus-btn {
                                    &.blue-btn {
                                        border-radius: 5px;
                                        height: 34px;
                                        padding: 2px 10px;
                                        font-size: 14px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        span {
                                            span {
                                                font-family: "Open Sans", sans-serif !important;
                                                font-size: 20px;
                                                line-height: 18px;
                                                margin-right: 10px;
                                                font-weight: 700;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cus-booking-history {
            width: 100%;
            display: inline-block;
            .past-month-filter {
                width: 220px;
                .MuiFormControl-root {
                    width: 100%;
                }
            }
            ul {
                padding: 0;
                margin: 30px 0 0 0;
                li {
                    padding: 25px;
                    margin: 0 0 15px 0;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 7px;
                    background-color: $light-blue-color;
                    &:last-child {
                        margin: 0;
                    }
                    .booking-history-left {
                        display: inline-block;
                        width: calc(100% - 200px);
                        padding-right: 20px;
                        .booking-history-top {
                            width: 100%;
                            display: flex;
                            margin-bottom: 20px;
                            .history-top-rep {
                                width: auto;
                                display: inline-block;
                                margin-right: 50px;
                                &:last-child {
                                    margin-right: 0;
                                }
                                p {
                                    color: $black-color;
                                    font-size: 16px;
                                    span {
                                        font-weight: 600;
                                        margin-left: 12px;
                                    }
                                }
                            }
                        }
                        .booking-history-details {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            .booking-history-img {
                                width: 150px;
                                height: 150px;
                                display: flex;
                                overflow: hidden;
                                border-radius: 10px;
                                margin-right: 30px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .history-details-right {
                                display: inline-block;
                                width: calc(100% - 180px);
                                .history-heading {
                                    width: 100%;
                                    display: inline-block;
                                    p {
                                        color: $black-color;
                                        font-size: 16px;
                                        font-weight: 600;
                                    }
                                }
                                .history-ticket-detail {
                                    width: 100%;
                                    display: inline-block;
                                    margin: 8px 0px;
                                    p {
                                        color: $black-color;
                                        font-size: 16px;
                                        margin: 0 0 8px 0;
                                        &:last-child {
                                            margin: 0;
                                        }
                                        .ticket-label-left {
                                            font-weight: normal;
                                            min-width: 110px;
                                            display: inline-block;
                                        }
                                        .ticket-label-right {
                                            font-weight: 600;
                                        }
                                    }
                                }
                                .booking-price-sec {
                                    width: 100%;
                                    display: inline-block;
                                    p {
                                        color: $blue-color;
                                        font-weight: 600;
                                        font-size: 18px;
                                        .price-label-left {
                                            font-weight: normal;
                                            min-width: 110px;
                                            display: inline-block;
                                            color: $black-color;
                                            font-size: 16px;
                                            font-weight: normal;
                                        }
                                        .price-label-right {
                                            font-weight: 600;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .booking-history-right {
                        display: inline-block;
                        width: 200px;
                        .right-links-rep {
                            width: 100%;
                            display: inline-block;
                            margin: 5px 0;
                            a {
                                color: $blue-color;
                                font-size: 16px;
                                margin: 5px 0px;
                                display: inline-block;
                                text-decoration: none;
                            }
                            button {
                                &.cus-btn {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Tabs List Design Css Ends */

/* Gift Vouchers Section Css Start */
.cus-gift-vouchers {
    width: 100%;
    display: inline-block;
    .cus-accordion {
        .accordion-listing {
            &:first-child {
                .accordion-summary {
                    padding-top: 0;
                }
            }
            .accordion-summary {
                padding: 15px 0;
                h3 {
                    font-weight: bold;
                    font-size: 20px;
                    margin: 0px;
                }
                .MuiAccordionSummary-expandIcon {
                    &.Mui-expanded {
                        -ms-transform: rotate(180deg) none;
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }
            .accordion-details {
                .cus-auth-form {
                    min-width: 50%;
                    .MuiFormControl-marginNormal {
                        margin-top: 0;
                        margin-bottom: 18px;
                    }
                }
            }
        }
    }
    ul {
        padding: 0;
        margin: 0;
        width: 100%;
        display: inline-block;
        li {
            padding: 0;
            margin: 0 25px 25px 0;
            width: 400px;
            display: inline-block;
            &:last-child,
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
            .gift-voucher-list {
                width: 100%;
                display: inline-block;
                position: relative;
                .gift-voucher-img {
                    width: 100%;
                    display: inline-block;
                    img {
                        width: 100%;
                        display: inline-block;
                    }
                }
                .gift-voucher-con {
                    position: absolute;
                    top: 35px;
                    left: 50%;
                    -ms-transform: translate(-50%, 0);
                    -webkit-transform: translate(-50%, 0);
                    -moz-transform: translate(-50%, 0);
                    -o-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    text-align: center;
                    width: 90%;
                    p {
                        color: $white-color;
                        font-size: 24px;
                        font-weight: 600;
                        b {
                            font-weight: 900;
                        }
                    }
                    .gift-voucher-bottom {
                        margin-top: 43px;
                        p {
                            font-size: 17px;
                            font-weight: 600;
                        }
                        button {
                            &.cus-btn {
                                height: 34px;
                                min-width: 175px;
                                margin-top: 13px;
                                font-size: 13px;
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Gift Vouchers Section Css Ends */

/* Help Page Css Start */
.cus-help-page {
    width: 100%;
    display: inline-block;
    padding-bottom: 40px;
    .cus-help-inner {
        .cus-help-form {
            width: 100%;
            margin: 40px auto;
            max-width: 60%;
            background-color: $white-color;
            padding: 70px;
            border-radius: 14px;
            .cus-help-form-inner {
                width: 100%;
                margin: 0 auto;
                max-width: 450px;
                .cus-sec-heading {
                    padding: 0 0 15px 0;
                }
                p {
                    font-size: 16px;
                    font-weight: 600;
                    color: $dark-grey-color;
                }
                .form-sec {
                    margin: 15px 0 0 0;
                    .MuiFormControl-marginNormal {
                        margin-top: 8px;
                        margin-bottom: 6px;
                    }
                    textarea {
                        resize: none;
                        margin: 8px 0 6px;
                        &:focus {
                            outline-color: $blue-color;
                        }
                    }
                    .refund-field {
                        margin-top: 8px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}
/* Help Page Css Ends */

/* Profile Page Css Start */
.cus-profile-page {
    width: 100%;
    display: inline-block;
    margin-bottom: 40px;
    .cus-profile-inner {
        width: 100%;
        display: inline-block;
        .profile-sidebar {
            width: 100%;
            display: inline-block;
            padding: 40px;
            .profile-pic {
                width: 165px;
                height: 165px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;
                padding: 40px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                &.uploaded-img {
                    padding: 0;
                    img {
                        object-fit: cover;
                    }
                }
            }
            .profile-con {
                width: 100%;
                display: inline-block;
                margin: 20px 0 0 0;
                div {
                    &:focus {
                        outline: none;
                    }
                }
                h4 {
                    color: $dark-grey-color;
                    font-size: 20px;
                    font-weight: 500;
                }
                h6 {
                    color: $blue-color;
                    font-size: 16px;
                    margin-top: 12px;
                    text-decoration: underline;
                }
            }
        }
        .profile-right-sec {
            padding-left: 60px;
        }
    }
}
/* Profile Page Css Ends */

/* Booking Page Css Start */
.cus-booking-page {
    .cus-booking-inner {
        .cus-back-arrow {
            display: flex;
            padding: 0 0 25px 0;
        }
        .booking-head-locations {
            h3 {
                font-size: 35px;
                font-weight: bold;
                color: $dark-grey-color;
            }
            h4 {
                font-size: 18px;
                justify-content: center;
                align-items: center;
                display: flex;
                color: $dark-grey-color;
                margin: 14px 0px;
                font-weight: normal;
                img {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .select-cat-sec {
            width: 100%;
            display: inline-block;
            padding: 0 80px;
            h2 {
                font-size: 26px;
                font-weight: bold;
                color: $dark-grey-color;
                margin: 30px 0px;
            }
            .seat-category-selection {
                padding-bottom: 30px;
                margin-bottom: 20px;
                border-radius: 18px;
                &.Bronze {
                    .header {
                        h5 {
                            background-color: $light-yellow-color;
                        }
                    }
                }
                &.Silver {
                    .header {
                        h5 {
                            background: linear-gradient(to right, rgba(213, 233, 223, 0.45), rgba(199, 218, 237, 1));
                            .price {
                                color: $blue-org-color;
                            }
                        }
                    }
                }
                &.Gold {
                    .header {
                        h5 {
                            background-color: #feed11;
                            .price {
                                color: $dark-yellow-color;
                            }
                        }
                    }
                }
                .header {
                    h5 {
                        padding: 12px;
                        font-weight: 600;
                        font-size: 20px;
                        color: $dark-grey-color;
                        border-radius: 9px;
                        .price {
                            color: $dark-grey-color;
                        }
                    }
                }
                .user-sel {
                    padding: 20px 30px;
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            padding: 8px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            h4 {
                                font-size: 15px;
                                font-weight: 600;
                                width: calc(100% - 72px);
                                padding-right: 10px;
                                word-break: break-word;
                                .price {
                                    margin-left: 10px;
                                    color: $light-yellow-color;
                                    font-weight: 600;
                                }
                            }
                            .select-user {
                                display: flex;
                                align-items: center;
                                padding: 3px;
                                border: 1px solid $light-grey-normal-color;
                                border-radius: 6px;
                                width: 72px;
                                svg {
                                    margin: 2px 4px;
                                    width: 20px;
                                    height: 20px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .select-btn {
                margin-top: 20px;
                button {
                    min-width: 220px;
                }
            }
        }
    }
    .payment-options-sec {
        width: 100%;
        display: inline-block;
        .payment-left-grid {
            .payment-options-tab {
                width: 100%;
                display: inline-block;
                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li {
                        padding: 10px 14px;
                        width: 100%;
                        margin-bottom: 6px;
                        display: flex;
                        cursor: pointer;
                        border-radius: 7px;
                        img {
                            width: 26px;
                            height: 24px;
                        }
                        p {
                            padding: 0 0 0 10px;
                            margin: 0;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .payment-right-sec {
        width: 100%;
        display: inline-block;
        p {
            margin: 20px 0 0;
            a {
                color: $blue-color;
            }
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                padding: 0;
                margin: 0 0 16px 0;
                flex-direction: column;
                .MuiFormControl-marginNormal {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .left-right-wrap {
                    width: 100%;
                    display: flex;
                    .left-50 {
                        width: 48%;
                        margin-right: 8px;
                    }
                    .right-50 {
                        width: 48%;
                        margin-left: 8px;
                    }
                }
                button {
                    &.cus-btn {
                        height: 56px;
                    }
                }
                .error {
                    width: 100%;
                    margin: 4px 0 0 0;
                    line-height: normal;
                }
            }
        }
        .agree-con-sec {
            p {
                color: $light-grey-normal-color;
                margin: 0 0 15px 0;
                svg {
                    margin-left: -10px;
                }
                a {
                    color: $blue-color;
                }
            }
        }
    }
    .booking-summary {
        padding: 30px 20px;
        border-radius: 20px;
        width: 100%;
        display: inline-block;
        h3 {
            font-size: 20px;
            font-weight: bold;
            color: $dark-grey-color;
            margin-bottom: 10px;
            padding: 0 15px;
        }
        ul {
            padding: 0;
            margin: 0;
            width: 100%;
            display: inline-block;
            li {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .select-user {
                    background: $white-color;
                    justify-content: center;
                    max-width: 70px;
                    margin: 0 auto;
                }
                p {
                    font-size: 16px;
                    color: $dark-grey-color;
                    font-weight: 600;
                    &:last-child {
                        color: $grey-color;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                    }
                    &.select-seat-link {
                        color: $blue-color;
                        text-decoration: underline;
                        font-size: 14px;
                        margin-top: 2px;
                        cursor: pointer;
                    }
                    &.float-right {
                        width: 100%;
                        text-align: right;
                    }
                }
                .select-user {
                    display: flex;
                    align-items: center;
                    padding: 3px;
                    border: 1px solid $light-grey-normal-color;
                    border-radius: 6px;
                    svg {
                        margin: 2px 4px;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .cus-booking-accordion {
        .cus-accordion {
            .accordion-listing {
                .accordion-summary {
                    padding: 15px 0;
                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                    }
                    .MuiAccordionSummary-expandIcon {
                        &.Mui-expanded {
                            -ms-transform: rotate(180deg) none;
                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            -o-transform: rotate(180deg);
                            transform: rotate(180deg);
                        }
                    }
                }
                .accordion-details {
                    p {
                        font-size: 15px;
                    }
                }
            }
        }
        .contact-detail-sec {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                margin: 0;
                width: 100%;
                display: flex;
                li {
                    padding: 0;
                    margin-right: 15px;
                    flex-direction: column;
                    &:last-child {
                        margin-right: 0;
                        width: auto;
                    }
                    button {
                        &.cus-btn {
                            height: 56px;
                            border-radius: 4px;
                            font-size: 16px;
                        }
                    }
                    p {
                        width: 100%;
                    }
                }
            }
        }
        .promotional-code-sec {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                margin: 0;
                width: 100%;
                display: inline-block;
                li {
                    width: 100%;
                    display: inline-block;
                    padding: 0;
                    .promo-field {
                        width: 50%;
                        float: left;
                        position: relative;
                        button {
                            &.cus-btn {
                                position: absolute;
                                right: 0;
                                top: 0;
                                height: 56px;
                                border-radius: 4px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Booking Page Css Ends */
/* Ticket Booking Modal Css Start */
.confirm-booking-modal {
    width: 100%;
    display: block;
    padding: 50px 0;
    max-width: 650px;
    margin: 0 auto;
    .your-ticket-sec {
        padding: 0 40px;
        .modal-multi-select {
            .MuiInputBase-root {
                .MuiAutocomplete-endAdornment {
                    top: 12px;
                }
            }
        }
        .cus-sec-heading {
            padding: 0 0 30px 0;
        }
        .ticket-confirm-img {
            img {
                width: 100%;
            }
        }
        .print-btn {
            margin: 25px 0 30px 0;
            width: calc(100% + 10px);
            display: flex;
            justify-content: space-between;
            button {
                &.cus-btn {
                    margin: 5px;
                    flex: 1;
                }
            }
        }
    }
    .thanks-buying-ticket {
        width: 100%;
        display: inline-block;
        h3 {
            color: $dark-grey-color;
            font-weight: bold;
            font-size: 30px;
        }
        p {
            font-size: 14px;
            font-weight: normal;
            color: $grey-color;
            margin: 10px 0 20px;
        }
    }
    button {
        &.cus-btn {
            margin: 10px 0 0 0;
        }
    }
    &.apply-promocode-modal {
        padding: 0;
        .your-ticket-sec {
            .cus-sec-heading {
                padding: 0 0 20px 0;
                img {
                    width: 85px;
                    height: 85px;
                }
            }
            .print-btn {
                width: 100%;
                text-align: center;
                margin: 15px 0 30px 0;
                display: flex;
                justify-content: space-evenly;
                button {
                    &.cus-btn {
                        margin: 0;
                        max-width: 180px;
                    }
                }
            }
        }
        p {
            margin: 0;
            text-align: center;
        }
        .send-promocode {
            width: 100%;
            display: inline-block;
            padding: 0;
            margin: 30px 0 20px 0;
            list-style: none;
            max-height: 374px;
            overflow-y: auto;
            li {
                display: inline-block;
                padding: 0px 13px;
                @include box-shadow;
                margin: 5px;
                border-radius: 4px;
                width: calc(50% - 10px);
                float: left;
                label {
                    width: 100%;
                    margin-right: 0;
                    span {
                        font-size: 14px;
                        color: $dark-grey-color;
                        font-weight: 500;
                        &.MuiTypography-root {
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: calc(100% - 40px);
                        }
                    }
                    .MuiCheckbox-root {
                        .MuiIconButton-label {
                            color: $blue-color;
                        }
                    }
                }
            }
        }
    }
    &.booked-ticket-page {
        .ticket-info {
            border-radius: 25px;
            display: flex;
            width: 100%;
            .details {
                background-color: #fffde9;
                padding: 25px;
                border-radius: 16px;
                width: calc(100% - 70px);
                .ticket-confirm-img {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .ticket-logo {
                        width: 140px;
                    }
                    .ticket-scanner {
                        width: 80px;
                    }
                }
                h4 {
                    font-weight: 600;
                    font-size: 16px;
                    color: $dark-grey-color;
                    margin: 30px 0 10px;
                }
                p {
                    font-weight: 600;
                    font-size: 16px;
                    color: $dark-grey-color;
                    margin: 0 0 8px 0;
                    width: 100%;
                    .cus-wht-space {
                        width: auto;
                        font-size: 16px;
                        margin: 0;
                        font-weight: 600;
                        padding-left: 10px;
                    }
                    span {
                        font-weight: 400;
                        font-size: 16px;
                        white-space: normal;
                        margin: 0;
                        width: 100%;
                        display: inline-block;
                    }
                    &.text-light {
                        font-weight: normal;
                        margin: 0;
                    }
                }
            }
            .left {
                background-color: #fff790;
                width: 70px;
                display: flex;
                position: relative;
                border-radius: 16px;
                h2 {
                    padding: 0;
                    word-break: break-word;
                    width: auto;
                    height: auto;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    font-weight: bold;
                    font-size: 26px;
                    color: $dark-grey-color;
                    text-align: center;
                    line-height: 50px;
                    max-width: 20px;
                }
            }
        }
        .your-ticket-sec {
            &.print-btn {
                width: 100%;
                padding: 0 20px;
            }
        }
    }
}
/* Ticket Booking Modal Css Ends */

/* Dashboad Sidebar Design Css Start */
.drawer-custom-menu {
    .MuiDrawer-paper {
        @include box-shadow;
        border: none;
    }
    .db-cus-logo {
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 20px 15px 5px 15px;
        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            width: 200px;
            display: inline-block;
        }
    }
    .sidebar-menus {
        padding: 15px;
        .sidepanel-item {
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 6px 16px;
            .MuiSvgIcon-root {
                margin-right: -15px;
                color: $grey-color;
            }
            .MuiListItemIcon-root {
                min-width: 40px;
            }
            img {
                width: 22px;
                height: 22px;
                &.icon-white {
                    display: none;
                }
                &.icon-grey {
                    display: block;
                }
            }
            span {
                color: $grey-color;
                font-weight: 600;
                font-size: 16px;
            }
            &.active {
                background-color: $blue-color;
                .MuiSvgIcon-root {
                    color: $white-color;
                }
                img {
                    &.icon-white {
                        display: block;
                    }
                    &.icon-grey {
                        display: none;
                    }
                }
                span {
                    color: $white-color;
                }
            }
        }
        .sidepanel-dropdown-item {
            padding-left: 12px;
            .sidepanel-item {
                &.active {
                    background-color: transparent;
                    span {
                        color: $blue-color;
                    }
                }
            }
        }
    }
}
/* Dashboad Sidebar Design Css Start */

/* Dashboard Page Css Start */
.db-main-container {
    width: 100%;
    display: inline-block;
    .db-top-bar {
        justify-content: space-between;
    }
    .db-main-con-inner {
        width: 100%;
        display: inline-block;
        .db-right-container {
            width: calc(100% - 240px);
            float: right;
            padding: 0 40px 0 60px;
        }
    }
}
.db-top-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0;
    .cus-page-search {
        margin: 0;
    }
    .promoter-header {
        width: auto;
        text-align: right;
        ul {
            width: 100%;
            display: flex;
            padding: 0;
            margin: 0;
            li {
                white-space: nowrap;
                padding: 0;
                .notification-sec {
                    background: $light-white-color;
                    border-radius: 8px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                    cursor: pointer;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        span {
                            top: -10px;
                            right: -5px;
                        }
                    }
                }
                .user-login {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .user-icon {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        border-radius: 50%;
                        background: $blue-color;
                        align-items: center;
                        justify-content: center;
                        margin-right: 12px;
                        img {
                            width: 18px;
                            height: 18px;
                            padding: 0;
                        }
                    }
                    span {
                        color: $blue-color;
                        font-weight: normal;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
.db-user-top-grids {
    .db-heading {
        h2 {
            img {
                width: 30px;
                height: 30px;
                margin-left: 25px;
            }
        }
    }
    .das-sum {
        padding-bottom: 30px;
        .das-sum-listing {
            .db-sum-card {
                padding: 35px 25px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.green {
                    background-image: linear-gradient(to left, #4fd3b2, #1bbe96);
                }
                &.blue {
                    background-image: linear-gradient(to left, #80abff, #5c8ef1);
                }
                &.pink {
                    background-image: linear-gradient(to left, #c28fff, #b070ff);
                }
                .db-sum-card-inner {
                    padding-left: 20px;
                    h3 {
                        font-size: 40px;
                        color: $white-color;
                        font-weight: bold;
                        margin-bottom: 6px;
                    }
                    p {
                        font-size: 20px;
                        color: $white-color;
                        font-weight: normal;
                    }
                }
                img {
                    float: right;
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }
}
.db-event {
    padding-bottom: 30px;
    .das-sum {
        .db-event-listing {
            .db-event-list {
                @include box-shadow;
                border-radius: 20px;
                width: 100%;
                display: inline-block;
                cursor: pointer;
                @include cus-transition;
                &:hover {
                    .event-list-img {
                        img {
                            @include cus-hover-transition;
                        }
                    }
                }
                .event-list-img {
                    width: 100%;
                    display: inline-block;
                    height: 260px;
                    overflow: hidden;
                    border-radius: 20px 20px 10px 10px;
                    img {
                        height: 100%;
                        object-fit: cover;
                        @include cus-transition;
                    }
                }
                .db-event-summary {
                    padding: 20px 24px;
                    width: 100%;
                    display: inline-block;
                    h2 {
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 7px;
                    }
                    .db-date-time {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 7px;
                        ul {
                            width: 100%;
                            display: flex;
                            padding: 0;
                            margin: 0;
                            align-items: center;
                            li {
                                padding: 0;
                                img {
                                    width: 17px;
                                    height: 17px;
                                    margin-right: 10px;
                                }
                                span {
                                    color: $grey-color;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                    p {
                        color: $blue-color;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ticket-left-sec {
                        width: auto;
                        display: flex;
                        align-items: center;
                        background-color: $light-blue-color;
                        padding: 8px 18px;
                        margin-top: 10px;
                        border-radius: 7px;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                        p {
                            font-weight: 600;
                            span {
                                font-weight: normal;
                            }
                        }
                    }
                    .view-counts {
                        position: absolute;
                        top: 12px;
                        left: 12px;
                        z-index: 7;
                        display: flex;
                        background-color: $white-color;
                        border-radius: 7px;
                        padding: 6px 8px;
                        align-items: center;
                        justify-content: center;
                        .eye-icon {
                            width: auto;
                            display: flex;
                            margin-right: 7px;
                            img {
                                width: 18px;
                                height: 18px;
                            }
                        }
                        h5 {
                            font-size: 12px;
                            font-weight: 600;
                            color: $black-color;
                            span {
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
.db-bar-events {
    padding: 40px 0;
    .db-upcoming-events {
        padding: 35px;
        width: 100%;
        display: inline-block;
        .db-upcoming-events-con {
            width: 100%;
            display: inline-block;
            max-height: 435px;
            overflow: auto;
        }
        li {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 5px 0px;
            margin-bottom: 15px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            .upcoming-img {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .upcoming-con {
                display: flex;
                flex-direction: column;
                width: calc(100% - 60px);
                padding: 0 0 0 15px;
                h5 {
                    font-weight: bold;
                    font-size: 16px;
                    color: $dark-grey-color;
                    margin-bottom: 4px;
                }
                p {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 7px;
                    }
                    span {
                        color: $grey-color;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
/* Dashboard Page Css Ends */

/* Your Events Page Css Start */
.cus-promoter-events {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 30px 0;
    }
    .cus-upcoming-shows {
        padding: 0;
    }
}
.event-actions {
    margin-top: 15px;
    .actions-icon {
        border: 1px solid $light-grey-normal-color;
        border-radius: 6px;
        display: flex;
        width: 44px;
        height: 36px;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .cus-button-groups {
        width: 100%;
        display: flex;
        button {
            flex: 1;
            &.cus-btn {
                height: 36px;
                min-width: 100px;
                font-size: 13px;
            }
        }
    }
}
.event-filter-dropdown {
    .MuiMenu-paper {
        top: 172px !important;
        background-color: transparent;
        @include box-shadow;
        ul {
            &.DayPicker_weekHeader_ul {
                li {
                    font-size: 14px;
                    padding: 0;
                    border: none;
                }
            }
            padding: 0;
            li {
                padding: 10px 20px;
                color: $dark-grey-color;
                font-weight: normal;
                font-size: 16px;
                border-bottom: 1px solid $light-white-color;
                background-color: transparent;
                &:last-child {
                    border-bottom: none;
                }
                &.selected-item {
                    color: $blue-color;
                    background-color: transparent;
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: $blue-color;
                    }
                }
                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $blue-color;
                }
            }
        }
    }
}
/* Your Events Page Css Ends */

/* Add Events Page Css Start */
.db-add-events-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 25px 0;
    }
    .db-add-event-con {
        width: 100%;
        display: flex;
        .sidebar-add-event {
            width: 160px;
            display: inline-block;
            div {
                &:focus {
                    outline: none;
                }
            }
            .add-event-img {
                width: 100%;
                display: inline-block;
                overflow: hidden;
                height: 150px;
                border-radius: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    object-fit: cover;
                }
            }
            h4 {
                color: $blue-color;
                font-weight: 600;
                text-decoration: underline;
                font-size: 14px;
                text-align: center;
                margin-top: 2px;
                cursor: pointer;
            }
        }
        .db-add-event-form {
            width: calc(100% - 160px);
            display: inline-block;
            padding-left: 40px;
            .load-time-datepicker {
                width: 100%;
                display: flex;
                align-items: center;
                .datepicker-date {
                    margin-left: 30px;
                    position: relative;
                    .MuiFormControl-root {
                        background-color: $white-color;
                        margin-right: 20px;
                    }
                    .filed-date {
                        .cal-icon {
                            position: absolute;
                            right: 30px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            .event-field {
                max-width: 80%;
                &.cus-load-time {
                    display: flex;
                    align-items: center;
                    p {
                        color: $dark-grey-color;
                        font-size: 16px;
                        padding-left: 15px;
                    }
                }
                .MuiFormControl-root {
                    background-color: $white-color;
                    margin: 8px 0;
                }
            }
        }
        .event-checkbox-sec {
            width: 100%;
            display: inline-block;
            margin: 15px 0;
            .event-checkbox-list {
                width: 100%;
                display: inline-block;
                margin-bottom: 12px;
                h6 {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 18px;
                }
                label {
                    font-weight: normal;
                    color: $grey-color;
                    align-items: flex-start;
                    span {
                        &.MuiCheckbox-root {
                            padding-top: 1px;
                            &:hover {
                                background-color: transparent;
                            }
                        }
                        &.MuiFormControlLabel-label {
                            font-size: 16px;
                        }
                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }
        }
        .cus-right-btn {
            button {
                &.cus-btn {
                    float: right;
                    min-width: 200px;
                    margin-top: 15px;
                }
            }
        }
    }
}
/* Add Events Page Css Ends */

/* Events Prices Page Css Start */
.db-events-prices-page {
    width: 100%;
    display: inline-block;
    .db-section-heading {
        margin-bottom: 10px;
    }
    .event-card-prices {
        .events-prices-listing {
            width: 100%;
            display: flex;
            .p-left-padd {
                padding-left: 30px;
                color: $grey-color;
                font-size: 15px;
                margin: -8px 0 10px 0;
            }
            span {
                &.MuiFormControlLabel-label {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-family: "Montserrat", sans-serif !important;
                }
                &.MuiIconButton-label {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .cus-booking-fee {
            width: 100%;
            display: inline-block;
            margin: 15px 0 22px 0;
            .cus-booking-fee {
                margin: 10px 0;
            }
            h6 {
                color: $dark-grey-color;
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 8px;
            }
            p {
                color: $grey-color;
                font-size: 15px;
            }
            ul {
                margin: 0 0 10px 0;
                padding: 0;
                width: 100%;
                display: flex;
                li {
                    padding: 0;
                    max-width: 400px;
                    margin-right: 45px;
                    .MuiTextField-root {
                        width: 100%;
                        .MuiInputBase-root {
                            background-color: $white-color;
                        }
                    }
                }
            }
        }
        .cus-general-prices {
            width: 100%;
            display: inline-block;
            .db-section-heading {
                margin: 10px 0 20px 0;
            }
            ul {
                margin: 0;
                padding: 0;
                width: 100%;
                display: inline-block;
                max-width: 60%;
                li {
                    padding: 0;
                    width: 100%;
                    display: inline-block;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    max-width: 45%;
                    float: left;
                    .price-container {
                        width: 100%;
                        display: inline-block;
                        position: relative;
                    
                        span {
                            &.actions-icon {
                                position: absolute;
                                right: -14px;
                                top: 47px;
                    
                                img {
                                    width: 12px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .MuiInputBase-root {
                        background-color: $white-color;
                    }
                    h5 {
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 8px;
                    }
                    .new-price-type {
                        width: 100%;
                        display: flex;
                        position: relative;
                        input {
                            padding-right: 120px;
                        }
                        button {
                            &.cus-btn {
                                position: absolute;
                                right: 0;
                                top: 0;
                                height: 100%;
                                min-width: 100px;
                                border-radius: 4px;
                            }
                        }
                    }
                    .error {
                        position: absolute;
                        bottom: -17px;
                        left: 0;
                        font-size: 14px;
                    }
                }
            }
        }
        .cus-extra-field {
            width: 100%;
            display: inline-block;
            margin: 0;
            .db-section-heading {
                margin: 0;
            }
            .cus-extra-field-inner {
                width: 100%;
                display: inline-block;
                max-width: 400px;
                position: relative;
                margin-top: 15px;
                .MuiTextField-root {
                    width: 100%;
                    background-color: $white-color;
                    margin-bottom: 15px;
                }
                .MuiTextField-root {
                    width: 100%;
                    background-color: $white-color;
                }
                .delete-icon {
                    position: absolute;
                    right: -45px;
                    top: 15px;
                    img {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                    }
                }
                button {
                    &.cus-btn {
                        margin-top: 0;
                        min-width: 220px;
                    }
                }
            }
        }
        .cus-right-btn {
            margin-top: 20px;
            button {
                &.cus-btn {
                    float: right;
                    min-width: 200px;
                }
            }
        }
    }
}
/* Events Prices Page Css Ends */

/* Events Performance Page Css Start */
.db-events-performance-page {
    width: 100%;
    display: inline-block;
    .db-events-per-container {
        .db-events-performance-inner {
            width: 100%;
            display: inline-block;
            margin-top: 30px;
            border-top: 2px solid $table-border-color;
            padding-top: 30px;
            &:first-child {
                margin-top: 0;
                border-top: none;
                padding-top: 0;
            }
            ul {
                padding: 0;
                margin: 0;
                li {
                    padding: 0;
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 30px;
                    h5 {
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 16px;
                    }
                    p {
                        color: $grey-color;
                        font-size: 15px;
                        margin: 0;
                    }
                    .datepicker-date {
                        width: 100%;
                        display: flex;
                        margin-top: 12px;
                        max-width: 450px;
                        .MuiTextField-root {
                            background-color: $white-color;
                            width: 100%;
                        }
                        .filed-date {
                            flex: 1;
                            margin-right: 15px;
                            button {
                                padding: 0;
                            }
                        }
                        .filed-time {
                            flex: 1;
                            position: relative;
                            img {
                                position: absolute;
                                top: 17px;
                                right: 15px;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .field-wraper {
                        width: 100%;
                        display: inline-block;
                        margin-top: 12px;
                        max-width: 450px;
                        .MuiFormControl-root {
                            margin: 0;
                            background-color: $white-color;
                        }
                    }
                }
            }
            .upload-img-sec {
                width: 150px;
                display: inline-block;
                text-align: center;
                margin: 15px 0 5px 0;
                div {
                    &:focus {
                        outline: none;
                    }
                }
                .upload-img-inner {
                    width: 150px;
                    height: 150px;
                    background: $white-color;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img {
                        width: 60px;
                        height: 60px;
                    }
                    &.uploaded-img {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                h5 {
                    color: $blue-color;
                    font-weight: 600;
                    text-decoration: underline;
                    font-size: 14px;
                    text-align: center;
                    margin-top: 6px;
                    cursor: pointer;
                }
            }
        }
    }
    .add-more-performance {
        width: 100%;
        display: inline-block;
        button {
            &.cus-btn {
                min-width: 300px;
            }
        }
    }
    .add-more-artist {
        width: 100%;
        display: inline-block;
        button {
            &.cus-btn {
                &.transparent-btn {
                    padding: 0;
                    color: $blue-color;
                    text-transform: none;
                    min-width: auto;
                    height: auto;
                    margin: 10px 0px 2px 0px;
                    text-decoration: underline;
                }
            }
        }
    }
    .cus-right-btn {
        width: 100%;
        display: inline-block;
        text-align: right;
        button {
            &.cus-btn {
                min-width: 200px;
                margin-left: 15px;
                &.grey-btn {
                    background-color: #83949c;
                    color: $white-color;
                    &:hover {
                        background-color: $grey-color;
                    }
                }
            }
        }
    }
}
/* Events Performance Page Css Ends */

/* Create Promocodes Page Css Start */
.db-create-promocodes {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 35px 0;
        button {
            &.cus-btn {
                min-width: 260px;
            }
        }
    }
    .cus-page-search {
        margin: 0 0 0 8px;
        max-width: 300px;
        .MuiFormControl-root {
            margin: 0;
        }
        input {
            padding: 10.5px 60px 10.5px 14px;
        }
        .search-icon {
            width: 40px;
            height: calc(100% - 16px);
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                .gift-voucher-list {
                    .gift-hover-actions {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $white-color;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 8;
                        border-radius: 4px;
                        .actions-icon {
                            border: 1px solid $light-grey-normal-color;
                            border-radius: 6px;
                            display: flex;
                            width: 44px;
                            height: 36px;
                            align-items: center;
                            justify-content: center;
                            margin-right: 5px;
                            cursor: pointer;
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .gift-voucher-con {
                        .gift-voucher-bottom {
                            margin-top: 40px;
                            button {
                                &.cus-btn {
                                    height: 38px;
                                    margin-top: 0;
                                    font-size: 16px;
                                    border-radius: 0;
                                    font-weight: bold;
                                }
                            }
                            p {
                                font-size: 14px;
                                margin-top: 10px;
                            }
                        }
                    }
                    &.blue-light-card {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                button {
                                    &.cus-btn {
                                        color: #106b98;
                                    }
                                }
                            }
                        }
                    }
                    &.blue-card {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                button {
                                    &.cus-btn {
                                        color: #052c79;
                                    }
                                }
                            }
                        }
                    }
                    &.green-card {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                button {
                                    &.cus-btn {
                                        color: #0f8062;
                                    }
                                }
                            }
                        }
                    }
                    &.purple-card {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                button {
                                    &.cus-btn {
                                        color: #520881;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Create Promocodes Page Css Ends */

/* New Promo Code Page Css Start */
.db-promo-code {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 30px 0;
    }
    .promo-code-inner {
        ul {
            padding: 0;
            margin: 0;
            li {
                padding: 0;
                display: inline-block;
                h5 {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 8px;
                }
                .field-wrap {
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 20px;
                    max-width: 40%;
                    .MuiTextField-root {
                        background-color: $white-color;
                    }
                    .datepicker-date {
                        width: 100%;
                        display: flex;
                        .date-picker-material-ui {
                            flex: 1;
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                    .radio-field-wrap {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin: 10px 0px;
                        fieldset {
                            display: flex;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .MuiTextField-root {
                            width: 160px;
                        }
                        .percentage-sec {
                            margin-left: 10px;
                            color: $dark-grey-color;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .create-promo-code-btn {
        margin-top: 10px;
        button {
            &.cus-btn {
                min-width: 250px;
            }
        }
    }
}
/* New Promo Code Page Css Ends */

/* Web Page Page Css Start */
.db-web-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 15px 0;
    }
    .web-page-inner {
        width: 100%;
        display: inline-block;
        max-width: 70%;
        p {
            color: $grey-color;
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 25px;
        }
        .web-page-container {
            width: 100%;
            display: flex;
            .event-img-con {
                width: 160px;
                display: inline-block;
                img {
                    width: 100%;
                    display: inline-block;
                    object-fit: cover;
                }
            }
            .web-page-right {
                width: calc(100% - 160px);
                display: inline-block;
                padding-left: 40px;
                h6 {
                    font-weight: 600;
                    font-size: 16px;
                    color: $dark-grey-color;
                }
                .MuiTextField-root {
                    background-color: $white-color;
                    margin-top: 8px;
                    margin-bottom: 12px;
                }
            }
        }
    }
    button {
        &.cus-btn {
            margin-top: 10px;
            min-width: 180px;
        }
    }
    .dramatix-flyer-btn {
        button {
            &.cus-btn {
                margin-top: 0px;
                min-width: 220px;
                border: 2px solid $blue-color;
                color: $blue-color;
            }
        }
    }
}
.custom-web-page {
    width: 100%;
    display: inline-block;
    .web-page-logo {
        width: 100%;
        display: inline-block;
        margin-bottom: 25px;
        img {
            max-width: 200px;
        }
    }
    .web-page-contents {
        width: 100%;
        display: inline-block;
        max-width: 95%;
        p {
            margin-bottom: 20px;
            color: $dark-grey-color;
            font-size: 16px;
        }
    }
}
/* Web Page Page Css Ends */

/* Tickets Page Css Start */
.db-tickets-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 30px 0;
        .MuiFormControl-root {
            background-color: $white-color;
        }
    }
    .cus-page-search {
        margin: 0;
        max-width: 300px;
        .MuiFormControl-root {
            margin: 0;
        }
        input {
            padding: 10.5px 60px 10.5px 14px;
        }
        .search-icon {
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    .ticket-card-info {
        min-height: 350px;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .tickets-fields {
        width: 100%;
        display: inline-block;
        ul {
            width: 100%;
            display: inline-block;
            padding: 0;
            li {
                padding: 0;
                margin-bottom: 20px;
                width: 33.33%;
                float: left;
                display: inline-block;
                &.full-width {
                    width: 100%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                p {
                    color: $grey-color;
                    font-weight: normal;
                    font-size: 16px;
                    margin-bottom: 2px;
                }
                h5 {
                    color: $black-color;
                    font-weight: 600;
                    font-size: 16px;
                    word-break: break-word;
                    &.mul-tickets-name {
                        min-height: 45px;
                    }
                }
            }
        }
    }
    .event-actions {
        .cus-button-groups {
            width: calc(100% - 49px);
            button {
                &.cus-btn {
                    min-width: auto;
                    margin: 0 5px;
                    &.blue-btn {
                        max-width: 120px;
                    }
                }
            }
        }
    }
}
/* Tickets Page Css Ends */

/* Promoter Profile Page Css Start */
.db-profile-page {
    .cus-tab-list {
        .tab-contents {
            .cus-booking-history {
                ul {
                    li {
                        align-items: flex-start;
                        .booking-history-left {
                            width: 100%;
                            padding-right: 0;
                        }
                        .booking-history-right {
                            width: auto;
                            position: absolute;
                            top: 25px;
                            right: 50px;
                            .right-links-rep {
                                margin: 0;
                                a {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
            .wallet-list {
                ul {
                    li {
                        .wallet-list-inner {
                            .add-money-btn {
                                button {
                                    &.cus-btn {
                                        &.blue-btn {
                                            padding: 2px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Promoter Profile Page Css Ends */

/* Manual Tickets Page Css Start */
.db-manual-tickets-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 20px 0;
    }
    .promo-code-inner {
        width: 100%;
        display: inline-block;
        max-width: 75%;
        .db-sub-heading {
            padding: 0 0 30px 0;
        }
        h5 {
            color: $dark-grey-color;
            font-weight: 600;
            font-size: 16px;
        }
        ul {
            padding: 0;
            margin: 0;
            max-width: 50%;
            li {
                padding: 0;
                .rep-filed {
                    width: 100%;
                    display: inline-block;
                    .MuiTextField-root {
                        background-color: $white-color;
                    }
                    .rep-adult {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .rep-field-half {
                            width: 50%;
                            .MuiFormControl-root {
                                margin: 12px 0 5px 0;
                                width: 100%;
                                background-color: $white-color;
                            }
                        }
                        .multi-icon {
                            padding: 10px 20px;
                            margin-top: 10px;
                            color: $dark-grey-color;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .create-ticket-btn {
        width: 100%;
        display: inline-block;
        margin-top: 15px;
        button {
            &.cus-btn {
                min-width: 200px;
            }
        }
    }
}
.db-manual-tickets-detail {
    width: 100%;
    display: inline-block;
    .db-back-arrow-sec {
        margin: 0;
    }
    .db-heading {
        justify-content: center;
        text-align: center;
    }
    .manual-ticket-sec {
        width: 100%;
        display: inline-block;
        padding-bottom: 40px;
        .manual-ticket-img {
            width: 100%;
            display: inline-block;
            margin-bottom: 30px;
            img {
                max-width: 420px;
            }
        }
        .manual-ticket-btn {
            width: 100%;
            display: inline-block;
            button {
                &.cus-btn {
                    min-width: 200px;
                }
            }
        }
    }
}
/* Manual Tickets Page Css Ends */

/* Transactions Page Css Start */
.db-transactions-page {
    width: 100%;
    display: inline-block;
    .cus-table {
        .cus-table-container {
            position: relative;
            max-height: calc(100vh - 350px);
            min-height: 250px;
        }
    }
    table {
        thead {
            tr {
                th {
                    position: sticky;
                    top: 0;
                    background: $white-color;
                    white-space: nowrap;
                }
            }
        }
    }
    .db-heading {
        padding: 0;
        .MuiFormControl-root {
            background-color: $white-color;
        }
    }
    .db-sub-heading {
        max-width: 80%;
    }
    .date-picker-sec {
        width: 850px;
        padding-right: 300px;
        float: right;
        text-align: right;
        display: flex;
        a {
            text-decoration: none;
            color: #5eb7df;
        }
        .DateRangePicker {
            padding-right: 8px;
        }
        table {
            width: 100%;
            display: block;
        }
    }
    .cus-page-search {
        margin: 0;
        .MuiFormControl-root {
            margin: 0;
        }
        input {
            padding: 10.5px 60px 10.5px 14px;
        }
        .search-icon {
            width: 40px;
            height: calc(100% - 16px);
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
    &.ad-transactions-statement-page {
        ul {
            padding: 0;
            li {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                p {
                    font-weight: normal;
                    font-size: 16px;
                    color: $dark-grey-color;
                }
                h5 {
                    font-weight: 600;
                    font-size: 16px;
                    color: $dark-grey-color;
                }
            }
        }
    }
}
/* Transactions Page Css Ends */

/* Custom Table Design Css Start */
.cus-table {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    .no-data-found {
        color: $grey-color;
        img {
            width: 120px;
            height: 120px;
        }
        h6 {
            font-size: 14px;
        }
    }
    table {
        tr {
            th {
                font-weight: 600;
                font-family: "Montserrat", sans-serif !important;
                white-space: nowrap;
            }
            td {
                font-weight: normal;
            }
            th,
            td {
                font-size: 14px;
                color: $dark-grey-color;
                border-color: $table-border-color;
                padding: 15px 20px;
                word-break: break-word;
            }
        }
    }
    .cus-table-pagination {
        padding: 20px !important;
    }
}
/* Custom Table Design Css Ends */

/* Date Picker Design Css Start */
.date-picker-sec {
    .DateRangePicker {
        // width: 100%;
        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover,
        .CalendarDay__selected:focus {
            background: $dark-blue-color;
            border: 1px double $dark-blue-color;
            outline: none;
        }
        .CalendarDay__selected_span {
            background: $blue-normal-color;
            border: 1px double $blue-normal-color;
        }
        .DateRangePicker_picker {
            z-index: 999;
            left: 50% !important;
            transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            -webkit-transform: translate(-50%, 0);
            -moz-transform: translate(-50%, 0);
            -o-transform: translate(-50%, 0);
        }
        .DayPickerNavigation_button__default {
            &:focus,
            &:hover {
                outline: none;
            }
        }
        .DateRangePickerInput_calendarIcon{
            display: none;
        }
    }
    .DateInput_input {
        // font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
        border-color: transparent;
        color: $dark-grey-color;
        &:focus {
            outline: none;
        }
    }
    .DateRangePickerInput__withBorder {
        border-radius: 4px;
        border: 1px solid $light-white-color;
    }
    .DateInput {
        border-radius: 4px;
        width: 140px;
    }
    .DayPickerNavigation__verticalDefault {
        height: 40px;
    }
    .DayPickerNavigation_svg__vertical {
        height: 30px;
        width: 30px;
    }
}
/* Date Picker Design Css Ends */

/* Admin Pages Styles */
/* Admin Auth Pages Css Start */
.auth-wrapper {
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    .split {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .centered {
            text-align: center;
            display: flex;
            height: 100%;
            background: $light-blue-color;
            position: relative;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .auth-wrapper-content {
                position: absolute;
                bottom: 20%;
                left: 50%;
                width: 75%;
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                -moz-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                h2 {
                    color: $dark-grey-color;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                p {
                    color: $dark-grey-color;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
        }
    }
    .cus-form {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        .cus-form-inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 100vh;
            padding: 30px 60px;
            overflow: auto;
            .cus-form-width {
                max-width: 500px;
                .auth-logo {
                    width: 100%;
                    display: inline-block;
                    margin: 0 0 40px 0;
                    min-height: 100px;
                    img {
                        max-width: 250px;
                    }
                }
                .cus-sec-heading {
                    padding: 20px 0;
                }
                .cus-sec-sub-heading {
                    padding-bottom: 15px;
                }
                .forgot-password-sec {
                    a {
                        float: right;
                        margin: 7px 0 0;
                        font-size: 16px;
                        color: $blue-color;
                        text-decoration: none;
                    }
                }
                .auth-btns {
                    button {
                        &.cus-btn {
                            height: 56px;
                            font-size: 16px;
                            margin-top: 15px;
                        }
                    }
                }
                .cus-account-txt {
                    p {
                        color: $dark-grey-color;
                        margin: 10px 0 5px 0;
                        font-size: 16px;
                        a {
                            color: $blue-color;
                        }
                    }
                }
            }
        }
    }
}
/* Admin Auth Pages Css Ends */

/* Sellers Page Css Start */
.db-sellers-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 40px;
        .MuiFormControl-root {
            background-color: $white-color;
            margin: 0;
        }
        .cus-page-search {
            margin: 0;
            max-width: 300px;
            float: right;
            input {
                padding: 10.5px 60px 10.5px 14px;
            }
        }
        button {
            &.cus-btn {
                margin: 0 8px;
            }
        }
    }
    .sellers-action-buttons {
        display: flex;
        justify-content: space-between;
        button {
            &.cus-btn {
                height: 38px;
                min-width: auto;
                padding: 6px 25px;
                margin: 0 6px;
                white-space: nowrap;
            }
            &.button-active {
                min-width: 140px;
                background-color: $black-color;
            }
            &.button-inactive {
                min-width: 140px;
                background-color: #83949c;
            }
        }
    }
    .cus-table {
        .box-shadow-none {
            max-height: calc(100vh - 300px);
        }
    }
}
.db-manual-tickets-page {
    &.ad-sallers-page {
        .promo-code-inner {
            .db-sub-heading {
                padding: 0 0 15px 0;
            }
        }
    }
}
/* Sellers Page Css Ends */

/* Seller Events Page Css Start */
.cus-sellers-events {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0;
    }
    .cus-tab-list {
        .tab-contents {
            .db-heading {
                padding: 0 0 30px 0;
            }
            .tab-inner-head {
                button {
                    &.cus-btn {
                        position: absolute;
                        right: 30px;
                        top: 103px;
                        z-index: 6;
                    }
                }
            }
        }
    }
    .cus-profile-fileds {
        position: relative;
    }
}
.no-events-founded {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 30px 0;
    color: $grey-color;
    font-weight: 600;
    font-size: 14px;
}
/* Seller Events Page Css Ends */

/* Add Seller Page Css Start */
.ad-add-seller-page {
    &.db-add-events-page {
        .db-add-event-con {
            .db-add-event-form {
                width: 100%;
                display: inline-block;
                padding-left: 0;
                .event-field {
                    max-width: 70%;
                    h5 {
                        color: $dark-grey-color;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 2px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
/* Add Seller Page Css Ends */

/* Your events Page Css Start */
.ad-your-events {
    &.cus-promoter-events {
        .db-heading {
            .MuiFormControl-root {
                background-color: $white-color;
                margin: 0;
            }
            .cus-page-search {
                margin: 0;
                max-width: 300px;
                float: right;
                input {
                    padding: 10.5px 60px 10.5px 14px;
                }
            }
            button {
                &.cus-btn {
                    margin: 0 8px;
                }
            }
        }
    }
}
/* Your events Page Css Ends */

/* Override Event Fees Css Start */
.ad-override-event-fees {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 30px;
    }
    .db-sub-heading {
        padding: 0 0 30px 0;
        max-width: 80%;
    }
    .bg-blue-color {
        min-height: 85vh;
    }
    .event-fees-select {
        flex: 2;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .event-fees-input {
        flex: 1;
        margin-bottom: 20px;
    }
    .event-fees-select,
    .event-fees-input {
        .MuiFormControl-root,
        .MuiFormControl-root {
            margin: 0;
            background-color: $white-color;
        }
    }
}
/* Override Event Fees Css Ends */

/* Profit Loss Page Css Start */
.profit-loss-form {
    width: 100%;
    display: inline-block;
    padding: 10px 10% 60px 10%;
    .changes-pass-icon {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-bottom: 20px;
        img {
            width: 80px;
            height: 80px;
        }
    }
    .cus-sec-heading {
        padding: 0 0 30px 0;
    }
    button {
        &.cus-btn {
            margin-top: 15px;
        }
    }
    .datepicker-date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .date-picker-material-ui {
            flex: 1;
            .MuiInputBase-root {
                padding: 0;
            }
        }
    }
}
/* Profit Loss Page Css Ends */

/* Expenditure Page Css Start */
.expenditure-form {
    width: 100%;
    display: inline-block;
    padding: 10px 5% 60px 5%;
    .changes-pass-icon {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-bottom: 15px;
        img {
            width: 80px;
            height: 80px;
        }
    }
    .cus-sec-heading {
        padding: 0 0 30px 0;
    }
    button {
        &.cus-btn {
            margin-top: 15px;
        }
    }
    .upload-img-sec {
        border: 1px solid $light-grey-normal-color;
        border-radius: 6px;
        opacity: 1;
        margin: 8px 0;
        padding: 20px;
        div {
            &:focus {
                outline: none;
            }
        }
        .upload-img-inner {
            width: auto;
            height: auto;
            background: $white-color;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin: 0 auto;
            img {
                width: 60px;
                height: 60px;
            }
            &.uploaded-img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    max-height: 200px;
                }
            }
        }
        h5 {
            color: $blue-color;
            font-weight: 600;
            text-decoration: underline;
            font-size: 14px;
            text-align: center;
            margin-top: 6px;
            cursor: pointer;
        }
    }
    ul {
        padding: 0;
        li {
            padding: 0;
            .rep-field-form {
                width: 100%;
                display: inline-block;
                .MuiFormControl-root {
                    margin: 8px 0;
                }
            }
        }
    }
}
.ad-expenditure-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0 0 30px 0;
    }
    .download-btn {
        button {
            &.cus-btn {
                height: 36px;
                font-size: 14px;
            }
        }
    }
    .table-action-btns {
        .event-actions {
            margin: 0;
        }
    }
    .cus-table {
        table {
            tr {
                th {
                    &:first-child {
                        white-space: nowrap;
                    }
                }
                td {
                    &:first-child {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
/* Expenditure Page Css Ends */

/* Refund Page Css Start */
.ad-refund-page {
    width: 100%;
    display: inline-block;
    .bg-blue-color {
        min-height: 85vh;
    }
    .db-heading {
        padding: 0 0 30px 0;
    }
    .db-sub-heading {
        padding: 0;
        p {
            font-weight: 600;
        }
    }
    ul {
        width: 100%;
        display: inline-block;
        padding-top: 12px;
        padding-bottom: 12px;
        li {
            padding: 8px 0;
            p {
                width: 180px;
                color: $grey-color;
                font-weight: normal;
                font-size: 16px;
                padding-right: 10px;
            }
            h5 {
                width: calc(100% - 180px);
                font-weight: 600;
                color: $dark-grey-color;
                font-size: 16px;
            }
        }
    }
    .refund-amount-sec {
        width: 100%;
        display: inline-block;
        .MuiFormControl-root {
            margin-top: 6px;
            background-color: $white-color;
        }
    }
}
/* Refund Page Css Ends */

/* Edit Tickets Page Css Start */
.ad-edit-ticket-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0;
    }
    .bg-blue-color {
        min-height: 85vh;
    }
    .event-field {
        width: 100%;
        display: inline-block;
        margin-bottom: 20px;
        .MuiFormControl-root {
            margin: 0;
            background-color: $white-color;
        }
    }
    ul {
        padding: 0;
        li {
            padding: 0;
            display: inline-block;
            .ticket-name-head {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;
                label {
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 16px;
                }
                a {
                    color: $blue-color;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .event-field {
                width: 100%;
                display: inline-block;
                margin-bottom: 20px;
                .MuiFormControl-root {
                    margin: 0;
                    background-color: $white-color;
                }
            }
        }
    }
}
/* Edit Tickets Page Css Ends */

/* Report Page Css Start */
.ad-report-page {
    .ad-report-table-head {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .report-head-right {
            display: flex;
            align-items: center;
            button {
                &.cus-btn {
                    height: 36px;
                    min-width: 100px;
                    font-size: 13px;
                    white-space: nowrap;
                }
            }
        }
    }
    .cus-bottom-price {
        ul {
            padding: 0;
            width: 400px;
            margin-top: 40px;
            li {
                justify-content: space-between;
                padding: 0;
                margin: 15px 0px;
                h2,
                h3,
                h4,
                h5 {
                    font-weight: 600;
                }
                h2,
                h3 {
                    font-size: 26px;
                }
                h4,
                h5 {
                    font-size: 20px;
                }
                h2,
                h4 {
                    color: $grey-color;
                }
                h3,
                h5 {
                    color: $dark-grey-color;
                }
            }
        }
    }
}
/* Report Page Css Ends */

/* Create Venue Page Css Start */
.db-create-venue-page {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0;
    }
    .db-sub-heading {
        .MuiFormControl-root {
            background-color: $white-color;
            margin: 0;
            min-width: 200px;
        }
    }
    .venue-listing-sec {
        .db-heading {
            .cus-page-search {
                margin: 0 0 0 8px;
                float: right;
                max-width: 220px;
                .MuiFormControl-root {
                    margin: 0;
                }
                input {
                    padding: 10.5px 60px 10.5px 14px;
                }
                .search-icon {
                    width: 40px;
                    height: calc(100% - 16px);
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .venue-listing-ul {
        width: calc(100% + 30px);
        float: left;
        margin-left: -15px;
        li {
            width: 33.33%;
            float: left;
            padding: 15px;
            cursor: pointer;
            .venue-listing-inner {
                background: $white-color;
                border-radius: 20px;
                width: 100%;
                padding: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    color: $dark-grey-color;
                    width: calc(100% - 25px);
                    padding-right: 15px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .venue-listing-arrow {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $light-blue-color;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    img {
                        padding: 7px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.db-create-venue-seat {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 30px 0 15px 0;
    }
    .db-sub-heading {
        padding: 30px 0 10px 0;
        p {
            font-weight: 600;
        }
    }
    .field-rep {
        width: 100%;
        display: inline-block;
        margin: 5px 0;
        .container-spacing-50 {
            padding: 20px;
        }
        &.cus-type-field {
            margin-top: 16px;
        }
        .MuiFormControl-root {
            background-color: $white-color;
            width: 100%;
            margin: 0;
        }
        .error {
            width: 100%;
        }
        .tables {
            display: inline-block;
            margin: 0 0 20px 0px;
            width: 100%;
            text-align: center;
            .tables-inner {
                width: 33.33%;
                display: inline-block;
                position: relative;
                margin: 120px 0px;
                counter-reset: number-counter;
                .circle-sec {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 7;
                    color: $dark-grey-color;
                    font-weight: 600;
                    font-size: 16px;
                }
                .tables-rep-sec {
                    width: 100%;
                    display: inline-block;
                    ul {
                        @include on-circle($item-count: 2, $circle-size: 16em, $item-size: 6em);
                        position: relative;
                        padding: 0;
                        list-style: none;
                        background: $table-border-color;
                        border-radius: 50%;
                        height: 16em;
                        width: 16em;
                        display: flex;
                        justify-items: center;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                        li {
                            display: block;
                            max-width: 100%;
                            transition: 0.15s;
                            .seat-inner-img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                svg {
                                    width: 50px;
                                    height: 50px;
                                }
                                .seat-empty {
                                    border: 1px solid $table-border-color;
                                    border-radius: 4px;
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }
                        &.chair-count-4 {
                            @include on-circle($item-count: 4, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-6 {
                            @include on-circle($item-count: 6, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-8 {
                            @include on-circle($item-count: 8, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-10 {
                            @include on-circle($item-count: 10, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-12 {
                            @include on-circle($item-count: 12, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-14 {
                            @include on-circle($item-count: 14, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-16 {
                            @include on-circle($item-count: 16, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-18 {
                            @include on-circle($item-count: 18, $circle-size: 16em, $item-size: 6em);
                        }
                        &.chair-count-20 {
                            @include on-circle($item-count: 20, $circle-size: 16em, $item-size: 6em);
                        }
                    }
                }
            }
        }
    }
    .table-zone-sec {
        .field-rep {
            .zone-main-sec {
                width: calc(100% + 2%);
                margin-left: -1%;
                margin-right: -1%;
                .zone-input-sec {
                    width: calc(100% - 1%);
                    margin-left: 1%;
                    display: flex;
                    align-items: flex-start;
                    .zone-input-here {
                        width: 40%;
                        .MuiFormControl-root {
                            background-color: $white-color;
                            margin: 0;
                        }
                    }
                    .zone-btn-here {
                        display: flex;
                        flex-direction: column;
                        .zone-btn-wrap {
                            width: 100%;
                            display: flex;
                        }
                    }
                }
            }
            button {
                &.cus-btn {
                    height: 56px;
                    margin-left: 15px;
                    min-width: 200px;
                    font-size: 16px;
                }
            }
            .table-grid-main {
                width: 100%;
                display: inline-block;
                max-height: 360px;
                margin: 15px 0;
                overflow: auto;
                .table-rep-grid {
                    width: 48%;
                    display: inline-block;
                    margin: 2% 1% 0 1%;
                    padding: 25px;
                    background-color: $tabs-light-blue;
                    border-radius: 8px;
                    position: relative;
                    h3 {
                        font-weight: 600;
                        font-size: 16px;
                        color: $dark-grey-color;
                        margin-bottom: 15px;
                    }
                    .field-rep {
                        margin-right: 10px;
                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                    .remove-icon {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        background: $light-blue-color;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid $tabs-light-blue;
                        cursor: pointer;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
    .add-zone-btn {
        margin-bottom: 30px;
        button {
            &.cus-btn {
                min-width: 200px;
                margin-right: 20px;
                .plus-con {
                    margin-right: 10px;
                    font-size: 24px;
                }
                .ref-icon {
                    margin-right: 10px;
                    display: flex;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
    .dup-rem-btns {
        width: 100%;
        display: flex;
        align-items: center;
        button {
            &.cus-btn {
                margin-right: 20px;
                min-width: 220px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .bottom-btns-group {
        text-align: right;
        margin-top: 15px;
        button {
            &.cus-btn {
                min-width: 200px;
                margin-left: 0;
                &:last-child {
                    margin-left: 20px;
                }
            }
        }
    }
    .update-seat-name-btn {
        button {
            &.cus-btn {
                margin-top: 9px;
                min-width: 230px;
            }
        }
    }
    .del-icon {
        display: inline-block;
        width: auto;
        position: absolute;
        top: 20px;
        right: -35px;
        img {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
}
.cus-seat-scrollable {
    width: 100%;
    .left-screen-arrangement {
        width: 35px;
        display: flex;
        margin-right: 10px;
    }
    .right-screen-arrangement {
        width: 35px;
        display: flex;
        margin-left: 10px;
    }
}
.cus-seat-arrangement {
    width: 100%;
    display: inline-block;
    min-width: 880px;
    padding-bottom: 15px;
    .cus-items-zone {
        width: 100%;
        display: inline-block;
        .cus-seats-items {
            width: 100%;
            display: inline-block;
            padding-left: 20px;
            .cus-seats-grid {
                width: 100%;
                display: inline-block;
                .cus-seat-plan {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    .seat-inner {
                        text-align: center;
                        margin: 2px;
                        width: 20px;
                        height: 20px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                        &.seat-empty {
                            border: 1px solid $table-border-color;
                            border-radius: 4px;
                        }
                    }
                    .cus-row-name {
                        display: flex;
                        align-items: center;
                        width: 30px;
                        text-align: center;
                        justify-content: center;
                        position: absolute;
                        left: -30px;
                        top: 5px;
                        h3 {
                            color: $dark-grey-color;
                            font-weight: 600;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .cus-center-heading {
        margin: 25px 0px 0 0px;
    }
    .cus-seats-options {
        display: inline-block;
        width: 100%;
        margin: 0 auto;
        max-width: 420px;
        ul {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin: 50px 0 20px 0;
            li {
                flex: 1 1;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-weight: 600;
                font-size: 16px;
                color: $dark-grey-color;
                background-color: $white-color;
                text-transform: capitalize;
                text-align: center;
                &:first-child {
                    justify-content: flex-start;
                }
                &:last-child {
                    justify-content: flex-end;
                }
                .circle {
                    border-radius: 50%;
                    border: 1px solid $table-border-color;
                    height: 13px;
                    width: 13px;
                    display: inline-block;
                    margin-top: -2px;
                    &.sold {
                        border: 1px solid $table-border-color;
                        background-color: $table-border-color;
                    }
                    &.select {
                        border: 1px solid $blue-color;
                        background-color: $blue-color;
                    }
                }
            }
        }
        .pay-select-btn {
            width: 100%;
            display: inline-block;
        }
    }
}
.box-curve-main {
    min-height: 35px;
    width: 100%;
    margin: 10px 0;
    .box-curve {
        width: 100%;
        height: 35px;
        border: 2px solid $table-border-color;
        border-radius: 6%/0px 0px 40px 40px;
        position: relative;
        background-color: $table-border-color;
        &.left {
            border-top-left-radius: 40px;
            border-bottom-left-radius: 40px;
            height: 100%;
        }
        &.right {
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            height: 100%;
        }
    }
}
.venue-page-edit {
    width: 100%;
    display: inline-block;
    .db-heading {
        padding: 0;
        .heading-btns-group {
            button {
                &.cus-btn {
                    min-width: 110px;
                    border: 2px solid $blue-color;
                    color: $blue-color;
                    text-transform: none;
                    font-size: 16px;
                    &.orange-btn {
                        background-color: $orange-color;
                        border: 2px solid $orange-color;
                        color: $white-color;
                        min-width: 160px;
                        &:hover,
                        &:focus {
                            background-color: $orange-hover-color;
                            border: 2px solid $orange-hover-color;
                            color: $white-color;
                        }
                    }
                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .db-sub-heading {
        padding: 15px 0 30px 0;
        p {
            display: flex;
            color: $grey-color;
            font-size: 14px;
            svg {
                margin-right: 8px;
                color: $blue-color;
            }
        }
    }
}
/* Create Venue Page Css Ends */

/* Footer Section Css Start */
.cus-wrapper + .cus-footer {
    margin-top: 0;
}
.cus-footer {
    width: 100%;
    display: inline-block;
    background-color: $light-grey-color;
    padding: 50px 0px 35px 0px;
    margin-top: 40px;
    position: relative;
    z-index: 7777;
    .footer-middle-sec {
        h3 {
            font-size: 16px;
            color: $dark-grey-color;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 25px;
        }
        .footer-logo-sec {
            width: 100%;
            display: inline-block;
            padding-right: 40px;
            img {
                max-width: 180px;
            }
            p {
                font-size: 15px;
                color: $grey-color;
            }
        }
        .footer-menus {
            width: 100%;
            display: inline-block;
            ul {
                padding: 0;
                li {
                    padding: 0;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    a {
                        font-size: 15px;
                        text-decoration: none;
                        color: $grey-color;
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $blue-color;
                        }
                    }
                }
            }
        }
        .footer-social-media {
            width: 100%;
            display: inline-block;
            padding-left: 50px;
            .social-icons-list {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0px;
                }
                .MuiAvatar-circle {
                    border: 1px solid $light-grey-normal-color;
                    background-color: $white-color;
                    width: 36px;
                    height: 36px;
                    cursor: pointer;
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .cus-news-letter {
            width: 100%;
            display: inline-block;
            .news-letter-field {
                width: 100%;
                display: inline-block;
                .news-letter-cover {
                    display: flex;
                    width: 100%;
                    position: relative;
                    .MuiFormControl-root {
                        width: 100%;
                        background-color: #fdfdfd;
                        label {
                            transform: translate(14px, 16px) scale(1);
                            &.MuiInputLabel-shrink {
                                transform: translate(14px, -6px) scale(0.75);
                            }
                        }
                        input {
                            padding: 14.5px 14px;
                        }
                    }
                    button {
                        background-color: $dark-grey-color;
                        position: absolute;
                        right: 1px;
                        height: 46px;
                        top: 1px;
                        min-width: 55px;
                        img {
                            width: 25px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom-sec {
        padding-top: 35px;
        p {
            color: $grey-color;
            font-size: 14px;
        }
    }
}
/* Footer Section Css Ends */

/* Error Messages */
.top-bar-msg {
    button {
        min-width: auto;
    }
}

/* Custom Chart Css Start */
.custom-chart-main {
    @include box-shadow;
    border-radius: 20px;
    opacity: 1;
    background: $white-color;
    padding: 35px;
    .custom-chart-heading {
        .chart-head-left {
            h6 {
                font-weight: bold;
                font-size: 20px;
                color: $dark-grey-color;
            }
        }
    }
}
/* Custom Chart Css Ends */

/* Modal Delete Css Start */
.cus-delete-modal {
    width: 100%;
    padding: 10px 40px 40px 40px;
    display: inline-block;
    .db-sub-heading {
        padding: 0 0 30px 0;
    }
    .delete-modal-btn {
        text-align: center;
        button {
            &.cus-btn {
                max-width: 220px;
            }
        }
    }
}
/* Modal Delete Css Ends */

/* Modal Notification Css Start */
.modal-notification {
    .MuiPaper-root {
        @include box-shadow;
        border-radius: 4px;
        top: 75px !important;
        .notification-dropdown {
            width: 320px;
            display: inline-block;
            max-height: 500px;
            overflow: auto;
            padding: 0;
            .dropdown-list {
                .media {
                    display: flex;
                    width: 100%;
                    padding: 12px;
                    border-bottom: 1px solid $light-white-color;
                    background-color: $white-color;
                    cursor: pointer;
                    &:hover {
                        background-color: $bg-light-color;
                    }
                    &.cus-read {
                        background-color: $bg-light-color;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                    .media-left {
                        width: 40px;
                        display: flex;
                        margin-right: 15px;
                    }
                    .media-body {
                        width: calc(100% - 55px);
                        .media-heading {
                            width: 100%;
                            float: left;
                            strong {
                                line-height: normal;
                                width: 100%;
                                display: inline-block;
                            }
                            span {
                                white-space: normal;
                                width: 100%;
                                display: flex;
                                text-align: left;
                                small {
                                    font-size: 10px;
                                    line-height: normal;
                                }
                            }
                        }
                        .media-msg {
                            width: 100%;
                            display: inline-block;
                            font-size: 12px;
                            color: $base-color;
                            p {
                                color: $base-color;
                            }
                        }
                    }
                }
                .notification-bottom {
                    width: 100%;
                    justify-content: center;
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
/* Modal Notification Css Ends */

/* Mixed Css Start */
.admin-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.toggle-close {
    display: flex;
    justify-content: flex-end;
}
.exist {
    .cus-chip {
        background-color: #5eb7df !important;
        color: #ffffff !important;
    }
}
.not-allowed {
    cursor: not-allowed !important;
}
.input-container {
    display: flex;
    .input-fields {
        padding: 5px;
    }
}
/* Mixed Css Ends */

.cus-upcoming-shows .upcoming-shows-inner .upcoming-shows-listing .cus-card-inner .card-contents .editer-html{
    color: #292929;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 40px;
    word-break: break-word;
    height: 60px;
}
.tox-statusbar{
    display: none !important;
}